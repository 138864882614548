import { Controller } from "stimulus"

export default class extends Controller {

  showList(event) {
    event.preventDefault();
    const target_id = event.currentTarget.getAttribute("id");
    const data = event.currentTarget.getAttribute("data-name");
    const at = event.currentTarget.getAttribute("at-type") || '';
    const fav = event.currentTarget.getAttribute("data-fav") != null;
    this.autocompleteList($(`#${target_id}`), data, at, fav);
  }

  autocompleteList(target, data_set, at, fav) {
    const data = target.closest('.autocompleteTarget').data(data_set);
    let at_list = [];
    if (at === "@")
      at_list.push("@", "@@")
    else
      at_list.push(at)
    if (target.closest('.autocompleteTarget').length <= 0)
      return;

    const default_config = {
      data,
      displayTpl: fav ? "<li>${fav} ${name}</li>" : "<li>${name}</li>",
      limit: data?.length || 0
    }

    at_list.map(el => {
      target.atwho(Object.assign({
        at: el,
        insertTpl: el + '${name}'
      }, default_config));
    });
  }
}