import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [
      "table",
      "column",
      "addColumnButton",
      "removeColumnButton",
      "indicatorMonth",
      "indicatorGoal",
      "indicatorValue",
      "managerId",
      "managerName",
      "cumulativeDegreeOfAchievement",
      "saveButton",
      "editButton",
      "cancelButton",
    ]
  }

  connect() {
    const controller = this;

    // 자동 합산 옵션
    document.querySelectorAll(`[type=checkbox][name*=is_auto_calc]`).forEach(el => {
      el.addEventListener('change', e => {
        let target = e.currentTarget || e.target;
        let { confirmMessage } = target.dataset;
        if (target.checked && confirm(confirmMessage)) {
          controller.addColumnButtonTarget.classList.add("d-none");
          controller.tableTarget.classList.add("d-none");
        } else {
          controller.columnTargets.forEach((el, i) => {
            el.setAttribute("draggable", true);
            el.dataset.order = i;
          });
          // 열 추가/제거 버튼 활성화
          controller.removeColumnButtonTargets.forEach(el => {
            el.parentNode.classList.remove('d-none');
          });
          controller.indicatorGoalTargets.forEach(el => el.removeAttribute("readonly"));
          controller.indicatorValueTargets.forEach(el => el.removeAttribute("readonly"));
          controller.addColumnButtonTarget.classList.remove("d-none");
          controller.tableTarget.classList.remove("d-none");
          target.checked = false;
        }
      });
    });
  }

  edit(e) {
    let target = e.currentTarget || e.target;
    let is_auto_calc;
    // // 자동 합산 옵션
    document.querySelectorAll(`[name*=is_auto_calc]`).forEach(el => {
      is_auto_calc = el.checked;
      el.removeAttribute("disabled");
    });

    if (is_auto_calc) {
      this.addColumnButtonTarget.classList.add("d-none");
      this.tableTarget.classList.add("d-none")
    } else {
      this.columnTargets.forEach((el, i) => {
        el.setAttribute("draggable", true);
        el.dataset.order = i;
      });
      // 열 추가/제거 버튼 활성화
      this.removeColumnButtonTargets.forEach(el => {
        el.parentNode.classList.remove('d-none');
      });
      this.indicatorGoalTargets.forEach(el => el.removeAttribute("readonly"));
      this.indicatorValueTargets.forEach(el => el.removeAttribute("readonly"));
      this.managerNameTargets.forEach(el => {
        el.dataset.action = "click->modal#fetch";
      });
    }
    this.cumulativeDegreeOfAchievementTarget.classList.toggle("d-none");
    target.parentNode.classList.add('d-none');
    this.cancelButtonTarget.parentNode.classList.remove('d-none');
  }

  cancel(e) {
    const controller = this;
    const url = e.target.form.getAttribute("url");
    const parsedUrl = new URL(`${location.origin}${url}`);
    fetch(parsedUrl, {
        method: "GET",
        headers: {
          "X-CSRF-Token": window.getMetaValue("csrf-token")
        }
      })
      .then(response => response.text())
      .then(body => {
        if (controller.element.parentNode)
          controller.element.outerHTML = body;
      })
  }

  orderReset(row) {
    row.querySelectorAll(`th:has(input[id^=performance_indicators]),
    td:has(input[id^=performance_indicators]),
    input[type=hidden][id^=performance_indicators][id*=goal]`).forEach((col, i) => {
      let cols = [...col.querySelectorAll('input[id^=performance_indicators]')];
      if (col.type === "hidden" && col.id.includes("performance_indicators")) {
        cols.push(col);
      }
      cols.forEach(el => {
        let beforeIndex = el.id.replace("performance_indicators_", "").split("_")[0];
        el.id = el.id.replace(`performance_indicators_${beforeIndex}`, `performance_indicators_${i}`);
        el.name = el.name.replace(`performance_indicators[${beforeIndex}]`, `performance_indicators[${i}]`);
      });
    });
  }

  // 열 추가
  addColumn(e) {
    let index = this.columnTargets.length;
    let th = document.createElement("th");
    let td;
    th.classList.add("align-middle");
    th.setAttribute("draggable", true);
    th.dataset.order = index;
    th.dataset.target = "indicator.column drag-item.column";
    th.innerHTML = `
    <div class="d-flex justify-content-between align-items-center">
      <div class="flex-column">
        <input type="number" name="performance_indicators[${index}][month]" id="performance_indicators_${index}_month" class="form-control hidden-arrows text-right" style="min-width: 60px;" data-target="indicator.indicatorMonth" step="any" required min="1" max="12">
      </div>
      <div class="flex-column">
        <button class="ui icon button bg-transparent p-0 pl-1" type="button">
          <i class="ui icon exchange alternate"></i>
        </button>
        <button class="ui icon button bg-transparent p-0 pl-1" data-action="indicator#removeColumn" data-target="indicator.removeColumnButton" type="button">
          <i class="ui icon minus"></i>
        </button>
      </div>
    </div>`;
    this.tableTarget.querySelectorAll("tr").forEach((tr, i) => {
      let children = tr.querySelectorAll('th, td');
      let lastChild = children[children.length - 1];
      td = document.createElement("td");
      switch (i) {
        case 0: // month
          tr.insertBefore(th, lastChild);
          break;
        case 1: // goal
          td.innerHTML = `<input type="number" name="performance_indicators[${index}][goal]" id="performance_indicators_${index}_goal" class="form-control hidden-arrows text-right" style="min-width: 60px;" data-target="indicator.indicatorGoal" step="any" value="0">`;
          tr.insertBefore(td, lastChild);
          break;
        case 2: // value
          td.innerHTML = `<input type="number" name="performance_indicators[${index}][value]" id="performance_indicators_${index}_value" class="form-control hidden-arrows text-right" style="min-width: 60px;" data-target="indicator.indicatorValue" step="any">`;
          tr.insertBefore(td, lastChild);
          break;
        default:
          tr.insertBefore(td, lastChild);
          break;
      }
      this.orderReset(tr);
    });
  }

  // 열 제거
  removeColumn(e) {
    let th = e.target.closest('th');
    let index = [...th.parentElement.childNodes].indexOf(th);
    let hidden = document.createElement("input");
    hidden.type = "hidden";
    hidden.id = `performance_indicators_${index}_goal`;
    hidden.name = `performance_indicators[${index}][goal]`;
    this.tableTarget.querySelectorAll("tr").forEach((tr, i) => {
      if (i == 1)
        tr.appendChild(hidden);
      tr.childNodes[index].remove();
      this.orderReset(tr);
    });
  }

  choiceManager(e) {
    let target = e.currentTarget || e.target;
    if (!target?.checked) return;
    let order = target.dataset.indicatorOrder;
    let name = target.closest("[data-search-info]").dataset.searchInfo.split(' ')[0];
    document.querySelector(`#performance_indicators_${order}_manager_id`).value = target.value;
    document.querySelector(`#performance_indicators_${order}_manager_name`).value = name;

    let modalController = this.application.getControllerForElementAndIdentifier(
      this.element.closest('[data-controller*=modal]'),
      "modal"
    );
    modalController.hide();
  }
}