import { charts } from "highcharts";
import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [
      "plannable",
      "activity",
      "chat",
      "task",
      "ranking",
      "analysisModal",
      "modalBody",
    ]
  }

  connect() {
    // this.chart_reflow();
  }

  //- 분석 차트 reflow(), 페이지가 처음 렌더링될 때 width가 짧게 나오는 현상이 있음.
  chart_reflow() {
    const controller = this;
    const tab_name = controller.element.dataset.tab || 'Project';
    charts.filter(el => el.container.closest(`div[data-tab="${tab_name}"`)).map(el => el.reflow());
  }

  //- 분석 로드해서 target에 넣어주기
  loadAnalysisUrl(url, target) {
    let thisController = this;
    target.classList.remove('d-none');
    target.innerHTML = "<i class='fa fa-spinner fa-pulse fa-4x mt-4 mb-4'></i>";
    fetch(url)
      .then(response => response.text())
      .then(body => {
        target.innerHTML = body;
        if (thisController.hasPlannableTarget && (target === thisController.plannableTarget)) {
          thisController.insertAndExecute(target);
        }
      });
  }

  //- 계획, 노트, 대화방, 태스크마다 따로 서로 다른 target 에 로드
  loadAnalysis(event) {
    let url = event.currentTarget.getAttribute("url");
    let subject = event.currentTarget.getAttribute("subject");
    switch (subject) {
      case "plan":
      case "issue":
        this.loadAnalysisUrl(url, this.plannableTarget);
        break;
      case "activity":
        this.loadAnalysisUrl(url, this.activityTarget);
        break;
      case "chat":
        this.loadAnalysisUrl(url, this.chatTarget);
        break;
      case "task":
        this.loadAnalysisUrl(url, this.taskTarget);
        break;
    }
  }

  //- 전체 랭킹보기 모달창 열기
  loadAllRanking(event) {
    let url = event.currentTarget.getAttribute("url");
    let modal_url = (url.indexOf("show_analysis_contents") !== -1) ? url.replace('show_analysis_contents', 'show_analysis_modal') : this.data.get('modal-url');
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.rankingTarget,
      "modal"
    );

    fetch(modal_url)
      .then(response => {
        if (response.status === 200 || response.status === 202) {
          response.text().then(body => {
            this.rankingTarget.innerHTML = body;
            this.modalBodyTarget.innerHTML = "<i class='fa fa-spinner fa-pulse fa-4x mt-4 mb-4'></i>";
            modalController.open();
            fetch(url)
              .then(response => response.text())
              .then(body => {
                this.modalBodyTarget.innerHTML = body;
                this.insertAndExecute(this.modalBodyTarget, body)
              });
          })
        } else {
          response.json().then(body => {
            alert(body.message)
          })
        }
      })
  }

  //- innerHTML로 분석 로드 시 chart가 로드되지 않으므로 javascript 를 따로 실행해주기
  insertAndExecute(target) {
    var scripts = Array.prototype.slice.call(target.getElementsByTagName("script"));
    for (var i = 0; i < scripts.length; i++) {
      if (scripts[i].src != "") {
        var tag = document.createElement("script");
        tag.src = scripts[i].src;
        document.getElementsByTagName("head")[0].appendChild(tag);
      } else {
        eval(scripts[i].innerHTML);
      }
    }
  }
}