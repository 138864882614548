import { Controller } from "stimulus"

export default class extends Controller {
    static get targets() {
        return [ "newForm", "newModal", "editForm", "editModal", "closeForm", "closeModal" ]
    }

    //- 새로 작성하는 경우의 모달창 열기 : 공용
    new(event) {
        let url = event.currentTarget.getAttribute("url");
        let modalController = this.application.getControllerForElementAndIdentifier(
            this.newFormTarget,
            "modal"
        );

        fetch(url)
            .then(response => response.text())
            .then(body => {
                this.newFormTarget.innerHTML = body;
                if (this.hasNewModalTarget) {
                    modalController.open();
                }
            });
    }

    //- 수정하는 경우의 모달창 열기 : 공용
    edit(event) {
        let url = event.currentTarget.getAttribute("url");
        let modalController = this.application.getControllerForElementAndIdentifier(
            this.editFormTarget,
            "modal"
        );

        fetch(url)
            .then(response => response.text())
            .then(body => {
                this.editFormTarget.innerHTML = body;
                if (this.hasEditModalTarget) {
                    modalController.open();
                }
            });
    }

    //- 평가하는 경우의 모달창 열기 : 공용
    close(event) {
        let url = event.currentTarget.getAttribute("url");
        let modalController = this.application.getControllerForElementAndIdentifier(
            this.closeFormTarget,
            "modal"
        );

        fetch(url)
            .then(response => response.text())
            .then(body => {
                this.closeFormTarget.innerHTML = body;
                if (this.hasCloseModalTarget) {
                    modalController.open();
                }
            });
    }

    submit(event) {
        let searchController = this.application.getControllerForElementAndIdentifier(
            this.element,
            "search"
        );
        let valid_cards = searchController.cardTargets.filter(el=>el.querySelector('input[type=checkbox]').checked);
        if (!valid_cards) return;
        let user_names = valid_cards.map(el=>el.dataset.userName).join(", ");

        const target = (event.currentTarget || event.target);
        let confirm_message = target.dataset.confirmMessage;
        if (target.dataset.mapName)
            confirm_message = confirm_message.replace('%{map}', target.dataset.mapName);
        if (user_names)
            confirm_message = confirm_message.replace('%{user_name}', user_names);

        if (confirm(confirm_message)) {
            target.form.submit();
        }
    }
}