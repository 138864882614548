import { Controller } from "stimulus"
import Tribute from "tributejs"
import Trix from "trix"
import "tributejs/dist/tribute.css"

export default class extends Controller {
  static get targets() {
    return ["field"]
  }

  connect() {
    this.editor = this.fieldTarget.editor;
    this.initializeTribute();
  }

  disconnect() {
    // this.tribue.detach(this.fieldTarget)
  }

  initializeTribute() {
    let mentioneeList = [];

    this.tribute = new Tribute({
      trigger: "@",
      allowSpaces: true,
      noMatchTemplate: () => '<span style:"visibility: hidden;"></span>',
      menuItemTemplate: (item) => `${item.original.fav}${item.original.name}`,
      lookup: (item, mentionText) => item.content,
      values: (text, cb) => {
        let trigger = '@',
            query = text;
        if (text.includes('@')) {
          trigger = '@@'; // 답글 필수
          query = query.replace('@', '');
        }
        return this.fetchUsers(trigger, query, (users) => {
          cb(users);
          mentioneeList = users;
        });
      },
    });
    this.tribute.attach(this.fieldTarget);
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this);
    this.fieldTarget.addEventListener('tribute-replaced', (e) => {
      let mention = e.detail.item?.original;
      let { mentionTriggerChar = "@", mentionText = "" } = e.detail.context;
      let content = "";
      if (!mention) {
        mention = mentioneeList.find(mentionee => mentionText.includes(mentionee.name))
        if (!mention) {
          this.editor.insertString(`${mentionTriggerChar}${mentionText}`);
          return
        }
        ([, content]= mentionText?.split(mention.name))
        e.detail.context.mentionText = ""
      }
  
      let attachment = new Trix.Attachment({ ...mention });
      this.editor.insertAttachment(attachment);
      this.editor.insertString(` ${content}`);
    });
  }

  fetchUsers(trigger, query = "", callback) {
    const parsedUrl = new URL(`${location.origin}${window.location.pathname}/mention_list.json`);
    parsedUrl.searchParams.set('trigger', trigger);
    parsedUrl.searchParams.set('query', query);
    fetch(parsedUrl)
      .then(response => response.json())
      .then(users => {
        users = users.sort((a, b) => {
          let result = -(a.fav.length - b.fav.length);
          if (result === 0)
            result = a.name - b.name;
          return result;
        })
        callback(users)
      })
      .catch(error => callback([]))
  }

  _pasteHtml(html, startPos, endPos) {
    var i;
    for (i = 0; i < endPos - startPos; i++) {
      this.editor.deleteInDirection("backward")
    }
  }
}