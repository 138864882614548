import moment from 'moment';
import { Controller } from 'stimulus';
import Calendar from 'tui-calendar';

export default class extends Controller {
  static targets = ['calendar', 'range'];
  static values = {
    url: String,
  };

  calendar;

  connect() {
    this.urlValue = this.data.get('url-value');
    this.initCalendar();
    this.fetch();
  }

  initCalendar() {
    const calendar = new Calendar(this.calendarTarget, {
      defaultView: 'month',
      isReadOnly: true,
      usageStatistics: false,
      useDetailPopup: true,
    });

    const _calendarTarget = this.calendarTarget;
    calendar.on({
      clickSchedule: function(event) {
        const titleElem = $(_calendarTarget).find('.tui-full-calendar-schedule-title')[0];
        const titleContent = `<a href="${event.schedule.raw.url}">${event.schedule.title}</a>`;
        titleElem.innerHTML = titleContent;
      },
    });

    this.calendar = calendar;
  }

  async fetch() {
    const oldFrom = this.rangeFromValue;
    const oldTo = this.rangeToValue;

    const newFrom = moment(this.calendar.getDateRangeStart().getTime()).format('YYYY-MM-DD');
    const newTo = moment(this.calendar.getDateRangeEnd().getTime()).format('YYYY-MM-DD');

    const isDateRangeRemainIdentical = ((oldFrom === newFrom) && (oldTo === newTo));
    if (isDateRangeRemainIdentical) {
      return;
    }

    const params = new URLSearchParams(location.search);
    params.append('cal_d_from', newFrom);
    params.append('cal_d_to', newTo);

    const paramstring = params.toString();

    const schedules = await fetch(`${this.urlValue}?${paramstring}`, {
      method: 'GET',
    }).
    then(response => response.json());

    this.setDateRangeFrom(newFrom);
    this.setDateRangeTo(newTo);

    this.setSchedules(schedules);
  }

  setSchedules(plans) {
    this.calendar.clear();
    this.calendar.createSchedules(plans);
    this.calendar.render();
  }

  async moveToday() {
    this.calendar.today();
    await this.fetch();
  }

  async movePrev() {
    this.calendar.prev();
    await this.fetch();
  }

  async moveNext() {
    this.calendar.next();
    await this.fetch();
  }

  setDateRangeFrom(from) {
    this.rangeFromValue = from;
    this.setDateRange();
  }

  setDateRangeTo(to) {
    this.rangeToValue = to;
    this.setDateRange();
  }

  setDateRange() {
    const rangeText = `${this.rangeFromValue} - ${this.rangeToValue}`;
    this.rangeTarget.textContent = rangeText;
  }
}
