import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ["sidemenu", "list"]
  }

  connect() {
    if (this.hasSidemenuTarget) {
      if (this.data.get("init-value") == "true") {
        this.open()
      } else {
        this.close()
      }
    }
  }

  open() {
    document.getElementById('projectnav').classList.remove("d-none")
    document.getElementById('projectnav').classList.add("col-md-4")
    document.getElementById('mainview').classList.remove("col")
    document.getElementById('mainview').classList.add("col-md-8")

    document.querySelector('#icon-open-close.fa-angle-right').parentNode.classList.add("d-md-none", "d-flex");

    let url;
    let strategyList = document.getElementById("strategyList")
    if (strategyList.innerHTML.length == 0) {
      strategyList.innerHTML = "<div class='text-center text-dark-link'><i class='fa fa-spinner fa-pulse fa-3x mt-4 mb-4'></i></div>";
      url = strategyList.dataset.url;
      fetch(url)
        .then(response => response.text())
        .then(body => {
          strategyList.innerHTML = body
        })
    }
    url = this.data.get("set-prefs-url");
    let parsedUrl = new URL(`${location.origin}${url}`);
    parsedUrl.searchParams.set("sidemenu_open", true);
    fetch(parsedUrl, {
      method: "PUT",
      headers: {
        'X-CSRF-Token': window.getMetaValue("csrf-token")
      },
      credentials: 'same-origin'
    })
    this.sidemenuTarget.dataset.sidemenuInitValue = true;
  }

  close() {
    document.getElementById('projectnav').classList.remove("col-md-4")
    document.getElementById('projectnav').classList.add("d-none")
    document.getElementById('mainview').classList.remove("col-md-8")
    document.getElementById('mainview').classList.add("col")

    document.querySelector('#icon-open-close.fa-angle-right').parentNode.classList.remove("d-md-none", "d-flex");
    let url = this.data.get("set-prefs-url");
    let parsedUrl = new URL(`${location.origin}${url}`);
    parsedUrl.searchParams.set("sidemenu_open", false);
    fetch(parsedUrl, {
      method: "PUT",
      headers: {
        'X-CSRF-Token': window.getMetaValue("csrf-token")
      },
      credentials: 'same-origin'
    })
    this.sidemenuTarget.dataset.sidemenuInitValue = false;
  }

  toggle() {
    if (document.getElementById('projectnav').classList.contains("d-none")) {
      this.open()
    } else {
      this.close()
    }
  }
}