import { Controller } from "stimulus"
import consumer from '../channels/consumer'
import UnreadCountCacheStore from '../lib/unread_count_cache_store'
import EventBus from '../lib/event_bus'

export default class extends Controller {
  static targets = ["loadedHtml"]

  initialize() {
    this.reload_channel_id = this.data.get("reload-channel");
    this.event_bus = new EventBus({ reload_channel_id: this.reload_channel_id });
    this.cache_store = new UnreadCountCacheStore(this.reload_channel_id);
    this.cache_store.init();
    let controller = this;

    if (!this.cache_store.has_subscription()) {
      this.load();

      let subscription = consumer.subscriptions.create({ channel: 'ReloadChannel', id: this.reload_channel_id }, {
        received(data) {
          controller.cache_store.set_value(data);
          controller.event_bus.publish('UNREAD_COUNT_CHANGED', {data: data, bounce: true});
        }
      });

      this.cache_store.add_subscription(subscription);
    }
  }

  connect() {
    this.remove_event_listener_func = this.event_bus.subscribe('UNREAD_COUNT_CHANGED', (custom_event) => {
      let data = custom_event.detail;

      if (data.reload_channel_id == this.reload_channel_id) {
        this.update_dom(data);
      }
    });

    let cached_data = this.cache_store.get_value();

    if (!!cached_data) {
      this.event_bus.publish('UNREAD_COUNT_CHANGED', { data: cached_data, bounce: false });
    }
  }

  disconnect() {
    this.remove_event_listener_func();
  }

  load() {
    let url = this.data.get("url");

    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.text()
        } else {
          throw "404"
        }
      })
      .then(html => {
        let data = {
          innerHTML: html
        };
        this.cache_store.set_value(data);
        this.event_bus.publish('UNREAD_COUNT_CHANGED', {data: data, bounce: false});
      })
      .catch(err => {
        console.log("error fetching: " + err)
      })
  }

  update_dom(payload) {
    let data = payload.data;
    let bounce = payload.bounce;
    let html;

    if (data.empty_innerHTML) {
      html = '';
    } else if (!!data.innerHTML) {
      html = data.innerHTML;
    }

    if (html != undefined) {
      this.loadedHtmlTarget.innerHTML = html;

      if (bounce && !!this.loadedHtmlTarget.firstElementChild) {
        this.loadedHtmlTarget.firstElementChild.classList.add('animated', 'bounce');
      }
    }
  }
}
