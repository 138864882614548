import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [
      "openButton",
      "closeButton",
      "openFormButton",
      "closeFormButton",
      "collapseView",
      "collapseFormView"
    ]
  }

  //- collapse 펼치기
  open() {
    this.openButtonTarget.classList.remove('basic');
    this.openButtonTarget.innerHTML = "<i class='icon angle down'></i>";
    this.openButtonTarget.dataset.action = "collapse#close";
    this.openButtonTarget.dataset.target = "collapse.closeButton";
    this.collapseViewTarget.classList.remove('d-none');
    let height = document.getElementsByClassName("chat-info-collapse")[0].offsetHeight;
    let chatroom_height = document.getElementById("chatroom").offsetHeight;
    document.getElementById("chatroom").style.height = (chatroom_height - height) + "px";
  }

  //- collapse 닫기
  close() {
    let height = document.getElementsByClassName("chat-info-collapse")[0].offsetHeight;
    this.closeButtonTarget.classList.add('basic');
    this.closeButtonTarget.innerHTML = "<i class='icon angle up'></i>";
    this.closeButtonTarget.dataset.action = "collapse#open";
    this.closeButtonTarget.dataset.target = "collapse.openButton";
    this.collapseViewTarget.classList.add('d-none');
    let chatroom_height = document.getElementById("chatroom").offsetHeight;
    document.getElementById("chatroom").style.height = (chatroom_height + height) + "px";
  }

  //- collapse form 펼치기
  openForm(event) {
    let target = event.currentTarget || event.target;
    let url = target.getAttribute('url');

    fetch(url)
      .then(response => response.text())
      .then(body => {
        this.openFormButtonTarget.classList.remove("basic")
        this.openFormButtonTarget.dataset.action = "collapse#closeForm";
        this.openFormButtonTarget.dataset.target = "collapse.closeFormButton";
        this.collapseFormViewTarget.classList.remove('d-none');
        this.collapseFormViewTarget.innerHTML = body;
        let height = this.collapseFormViewTarget.offsetHeight;
        let chatroom_height = document.getElementById("chatroom").offsetHeight;
        document.getElementById("chatroom").style.height = (chatroom_height - height) + "px";
      });
  }

  //- collapse form 닫기
  closeForm() {
    let height = this.collapseFormViewTarget.offsetHeight;
    this.closeFormButtonTarget.classList.add("basic");
    this.closeFormButtonTarget.dataset.action = "collapse#openForm";
    this.closeFormButtonTarget.dataset.target = "collapse.openFormButton";
    this.collapseFormViewTarget.classList.add('d-none');
    let chatroom_height = document.getElementById("chatroom").offsetHeight;
    document.getElementById("chatroom").style.height = (chatroom_height + height) + "px";
  }
}