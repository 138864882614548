import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [
      "score",
      "comment",
      "tag",
      "form",
      "description",
      "initiatives",
      "initiative",
      "nextInitiative",
      "removeInitiativeButton",
      "planList",
      "printList",
      "printIcon",
      "noteList",
      "taskList",
      "loadDiv",
      "team",
      "project",
      "resourceForm",
      "attributeForm",
      "allPlanTags",
      "planTags",
      "areaNotToPrint",
      "evaluation",
      "distributions"
    ]
  }

  connect() {
    //- 새 계획 생성 모달을 처음 열 때는 프로젝트/팀을 선택하지 않았으므로 form 을 숨기기
    if (this.hasAttributeFormTarget) {
      this.toggleAttributeForm();
    }

    if (this.hasProjectTarget && this.hasTeamTarget)
      this.beforeGetSelectableResources();

    let url = this.data.get("contentsUrl");
    //- 계획 내에서 노트 리스트 보기 시 페이지 변경 때마다 다시 노트 리스트를 로드
    if (this.hasNoteListTarget) {
      if (this.noteListTarget.classList.contains('open-note-list') || location.search.split('page=')[1] != undefined) {
        url += url.split("page=").length == 1 ? "?" : "&";
        url += `page=${location.search.split('page=')[1]}`;
        this.noteListTarget.classList.add('open-note-list');
        this.noteListTarget.classList.remove('d-none');
        this.loadNoteList(url);
      }
    }

    this.initializeDetailTab();
    this.resetRemoveInitiativeButtons();
  }

  // 프로젝트/팀 선택 이전의 값 저장해두기
  beforeGetSelectableResources() {
    this.projectTarget.addEventListener('change', (e) => this.getSelectableTeams());
  }

  //- 계획(이슈) 생성 시 프로젝트 변경하면 선택할 수 있는 팀 목록 변경
  async getSelectableTeams() {
    let team;
    const project = this.projectTarget;
    const url = this.data.get("selectableResourcesUrl");
    let parsedUrl = new URL(`${location.origin}${url}`);
    if (this.hasTeamTarget) {
      team = this.teamTarget;
      if ((team?.type == "checkbox" && team?.checked) || team?.type === "select-one")
        parsedUrl.searchParams.set('plannable[team_id]', team.value);
    }
    parsedUrl.searchParams.set('plannable[project_id]', project?.value);
    await this.getSelectableResources(parsedUrl);
    this.toggleAttributeForm();
  }

  async getSelectableResources(parsedUrl) {
    const controller = this;
    const current_url = new URL(location);
    const project = this.projectTarget;
    const resourceForm = this.resourceFormTarget;
    current_url.searchParams.forEach((v, name) => {
      if ((!parsedUrl.searchParams.get('team_id') && ['head_quarter_id'].includes(name)) ||
        (!parsedUrl.searchParams.get('project_id') && ['detailed_strategy_id', 'strategy_id'].includes(name)))
        parsedUrl.searchParams.set(name, v);
    });

    await fetch(parsedUrl, {
      method: 'get',
      headers: {
        "X-CSRF-Token": window.getMetaValue("csrf-token")
      }
    })
      .then(response => response.json())
      .then(body => {
        if (body.message) {
          alert(body.message);
          $(project).dropdown('restore defaults')
          return false;
        }
        resourceForm.innerHTML = body.selectable_resources;
        controller.beforeGetSelectableResources();
      });
  }

  //- 새 계획 생성 시 프로젝트 및 팀을 선택해야 form 보여주고, 아니면 숨기기
  toggleAttributeForm() {
    const controller = this;
    const selected_project = this.projectTarget.value;
    const selected_teams = this.teamTargets
      .filter(input => {
        if (input.type == "checkbox") {
          const accordion_controller = controller.application.getControllerForElementAndIdentifier(
            input.closest(".accordion"),
            "accordion"
          )
          document.querySelectorAll(`input[type="checkbox"][id*="${input.id}"]`)
            .forEach(el => {
              input.checked ? accordion_controller?.open() : accordion_controller?.close()
            });
          document.querySelectorAll(`input[type="checkbox"][id*="${input.id.replace('_id', '_note_reminder_recipients')}_"]`)
            .forEach(el => {
              input.checked ? el.removeAttribute('disabled') : el.setAttribute('disabled', true);
            });
          return input.checked;
        } else {
          return input.value.length > 0;
        }
      });
    if ((!this.hasProjectTarget && !this.hasTeamTarget) ||
        ( selected_project.length > 0 && selected_teams.length > 0)) {
      this.attributeFormTarget.classList.remove('d-none')
    } else {
      this.attributeFormTarget.classList.add('d-none')
    }
  }

  initializeDetailTab() {
    if (!this.hasCancelButtonTarget)
      return;
    const cancelButton = this.cancelButtonTarget;
    const formData = new FormData(cancelButton.form);
    const description = formData.get('plan[description]');

    cancelButton.addEventListener('click', e => {
      this.descriptionTarget.value = description;
      this.descriptionTarget.setAttribute("readonly", true);
    });
  }

  edit(e) {
    this.descriptionTarget.removeAttribute("readonly");
  }

  update(event) {
    event.preventDefault();
    const controller = this;
    const currentTarget = event.currentTarget || event.target;
    const url = currentTarget.getAttribute("action");
    const load_url = currentTarget.getAttribute("url");

    const formData = new FormData(event.target);
    Rails.ajax({
      type: "PUT",
      url: url,
      headers: {
        "X-CSRF-Token": window.getMetaValue("csrf-token"),
        "Content-Type": "application/json"
      },
      data: formData,
      success: function (data) {
        if (load_url && data.status === "updated") {
          // 본부장이 프로젝트 설명 수정, KPI 목표 및 실적 수정
          controller.fetch_url(load_url, controller.element);
        }
      }
    });
  }

  fetch_url(url, target) {
    fetch(url)
      .then(response => response.text())
      .then(body => {
        target.outerHTML = body;
      });
  }

  //- 계획 확인 시 점수와 코멘트가 모두 입력되면 submit
  planClose(event) {
    event.preventDefault()
    if (this.scoreTarget.value.length == 0) {
      return alert(this.data.get("scoreMessage"))
    } else if (this.scoreTarget.value > parseInt(this.data.get("maxScore"))) {
      return alert(this.data.get("maxScoreMessage"))
    } else if (this.commentTarget.value.length == 0) {
      return alert(this.data.get("commentMessage"))
    }

    const formData = new FormData(this.formTarget);
    const ifEvaluateTeamusers = (formData.getAll('plan[if_evaluate_teamusers]').slice(-1) == "1");
    if (ifEvaluateTeamusers) {
      const distributions = this.distributionsTarget.querySelectorAll('input[type="number"][name^=distribution]')
      const numbers = Array.from(distributions).map(el => parseInt(el.value));
      const containsPenalty = (formData.getAll('plan[contains_penalty]').slice(-1) == "1");

      if (containsPenalty) {
        const conditionSatisfied = (
          numbers.every(num => (Number.isInteger(num) && num != 0)) &&
          (numbers.filter(num => num > 0).reduce((a, b) => a + b, 0) == 100) &&
          (numbers.filter(num => num < 0).reduce((a, b) => a + b, 0) == -100)
        )

        if (!conditionSatisfied) {
          return alert([this.data.get('evaluationMessage'), this.data.get('penaltyMessage')].join('\n'));
        }
      } else {
        const conditionSatisfied = (
          numbers.every(num => (Number.isInteger(num) && num >= 0)) &&
          (numbers.filter(num => num > 0).reduce((a, b) => a + b, 0) == 100)
        )

        if (!conditionSatisfied) {
          return alert(this.data.get('evaluationMessage'));
        }
      }
    }

    let selected_tags = this.tagTargets.filter(tag => tag.checked)
    let values_of_selected_tags = []
    selected_tags.forEach(el => {
      values_of_selected_tags.push(el.parentElement.children[1].textContent)
    })
    if (confirm(this.data.get("confirmTitle") + "\n\n" +
        this.data.get("confirmScore") + " : " + this.scoreTarget.value + "\n" +
        this.data.get("confirmComment") + " : " + this.commentTarget.value + "\n" +
        this.data.get("confirmTag") + " : " + (values_of_selected_tags.length > 0 ? values_of_selected_tags.join(", ") : this.data.get("confirmNoTag")) + "\n\n" +
        this.data.get("confirm"))) {

      const requestInit = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: $(this.formTarget).serialize(),
      }
      fetch(this.formTarget.action, requestInit)
        .then(response => {
          if (!response.ok) {
            throw new Error();
          }

          return response.json();
        })
        .then(body => {
          console.debug(body);
          if (body.messages) {
            return alert(body.messages.join('\n'));
          }

          if (body.reload) {
            location.reload();
          }
        })
        .catch(error => {

        })
        .finally(() => {

        })

    }
  }

  //- Initiative 추가(OKR맵)
  addInitiative(event) {
    event.stopPropagation();
    const target = event.currentTarget || event.target;
    const initiative_counter = this.initiativeTargets.length;

    const url = target.getAttribute("url");
    const parsedUrl = new URL(url, location);
    parsedUrl.searchParams.append('initiative_counter', initiative_counter);
    fetch(parsedUrl)
      .then(response => {
        if (response.status != 200) return;
        response.text().then(body => {
          this.nextInitiativeTarget.outerHTML = body;
          this.resetRemoveInitiativeButtons();
        });
      })
  }

  //- Initiative 제거(OKR맵)
  removeInitiative(event) {
    event.stopPropagation();
    const target = event.currentTarget || event.target;
    target.closest('[class^=initiative]').remove();
    this.resetRemoveInitiativeButtons();
  }

  //- Initiative 마지막 제거버튼만 남겨두기(OKR맵)
  resetRemoveInitiativeButtons() {
    if (this.hasInitiativeTarget) {
      const i = document.createElement('i');
      i.className = "fa fa-minus";

      const button = document.createElement('button');
      button.className = "btn btn-outline-secondary";
      button.setAttribute('type', 'button');
      button.setAttribute('data-action', 'plan#removeInitiative');
      button.setAttribute('data-target', 'plan.removeInitiativeButton');
      button.appendChild(i);

      const newDiv = document.createElement('div');
      newDiv.appendChild(button);

      this.initiativeTargets.slice(-1)[0].appendChild(newDiv);
    }

    if (!this.hasRemoveInitiativeButtonTarget)
      return;
    this.removeInitiativeButtonTargets.slice(0, -1).forEach(el => el.parentNode.remove());
  }

  //- 계획 내 노트 모두 인쇄하기
  printNoteList(event) {
    const target = event.currentTarget || event.target;
    target.setAttribute('disabled', true);
    this.printIconTarget.classList.replace("fa-download", "fa-spinner");
    this.printIconTarget.classList.add("fa-spin");
    let url = this.data.get("printUrl");
    fetch(url)
      .then(response => response.text())
      .then(body => {
        this.printListTarget.innerHTML = body;
        window.setTimeout(() => {
          window.print();
          this.printIconTarget.classList.remove("fa-spin");
          this.printIconTarget.classList.replace("fa-spinner", "fa-download");
          target.removeAttribute('disabled');
          this.printListTarget.innerHTML = "";
        }, 3000);
      });
  }

  //- 계획 내에서 노트 리스트를 로드하기
  showNoteList() {
    let url = this.data.get("contentsUrl");
    if (this.noteListTarget.classList.contains('open-note-list')) {
      this.noteListTarget.classList.remove('open-note-list');
      this.noteListTarget.classList.add('d-none');
    } else {
      this.noteListTarget.classList.add('open-note-list');
      this.noteListTarget.classList.remove('d-none');
      this.loadNoteList(url)
    }
  }

  loadNoteList(url) {
    this.noteListTarget.innerHTML = "<div class='text-center'><i class='fa fa-spinner fa-pulse fa-4x mt-4 mb-4'></i></div>";
    fetch(url)
      .then(response => response.text())
      .then(body => {
        //- 노트 리스트 넣어주고
        this.noteListTarget.innerHTML = body;
        //- 목록에서 즐겨찾기 tooltip 동작할 수 있도록 추가
        $("[data-toggle='tooltip']").tooltip();
      });
  }

  //- 계획 목록에서 더보기로 30개씩 추가 로드
  load(event) {
    const target = event.currentTarget || event.target
    const url = target.getAttribute("url");
    if (url) {
      target.removeAttribute("url");
      let spinner = document.createElement("i");
      spinner.setAttribute("class", "fa fa-spinner fa-pulse ml-2");
      target.appendChild(spinner);
      fetch(url)
        .then(response => response.text())
        .then(body => {
          this.loadDivTarget.outerHTML = body;
          target.classList.add('d-none');
        });
    }
  }

  //- 계획 내 노트 모두 인쇄하기
  printPlanDashboard(event) {
    event.currentTarget.setAttribute('disabled', true);
    this.printIconTarget.classList.replace("fa-download", "fa-spinner");
    this.printIconTarget.classList.add("fa-spin");

    if (this.areaNotToPrintTarget.classList.contains('d-print-none'))
      this.areaNotToPrintTarget.classList.remove('d-print-none')
    window.setTimeout(() => {
      window.print();
      this.printIconTarget.classList.remove("fa-spin");
      this.printIconTarget.classList.replace("fa-spinner", "fa-download");
      event.currentTarget.removeAttribute('disabled');
    }, 3000);

    this.areaNotToPrintTarget.classList.add('d-print-none')
  }

  //- 통제활동 대시보드에서 통제활동 리스트를 로드하기
  showPlanList() {
    let url = this.data.get("contentsUrl");
    if (this.planListTarget.classList.contains('open-plan-list')) {
      this.planListTarget.classList.remove('open-plan-list');
      this.planListTarget.classList.add('d-none');
    } else {
      this.planListTarget.classList.add('open-plan-list');
      this.planListTarget.classList.remove('d-none');
      this.loadPlanList(url)
    }
  }

  loadPlanList(url) {
    this.planListTarget.innerHTML = "<div class='text-center'><i class='fa fa-spinner fa-pulse fa-4x mt-4 mb-4'></i></div>"
    let checked_one = this.planTagsTargets.filter(checkbox => checkbox.checked);
    let parseUrl = new URL(`${url}`);
    if (checked_one.length > 0) {
      checked_one.forEach((el, i) => {
        parseUrl.searchParams.append("plan_tags[]", el.value);
      });
    }
    fetch(parseUrl)
      .then(response => response.text())
      .then(body => {
        //- 노트 리스트 넣어주고
        this.planListTarget.innerHTML = body;
        //- 목록에서 즐겨찾기 tooltip 동작할 수 있도록 추가
        $("[data-toggle='tooltip']").tooltip();
      });
  }

  //- 모든 체크박스의 체크를 하거나 제외하거나
  checkAll(event) {
    let url = this.data.get("contentsUrl");
    if (event.currentTarget.checked) {
      this.planTagsTargets.forEach((el, i) => {
        el.checked = true
      });
    } else {
      this.planTagsTargets.forEach((el, i) => {
        el.checked = false
      });
    }
    this.loadPlanList(url)
  }

  //- 하나의 체크박스만 선택 / 해제
  checkOne(event) {
    let url = this.data.get("contentsUrl");
    if (event.currentTarget.checked) {
      if (!this.allPlanTagsTarget.checked && (this.planTagsTargets.filter(checkbox => checkbox.checked).length == this.planTagsTargets.length)) {
        this.allPlanTagsTarget.checked = true
      }
    } else {
      if (this.allPlanTagsTarget.checked) {
        this.allPlanTagsTarget.checked = false
      }
    }
    if (this.planListTarget.classList.contains('open-plan-list')) {
      this.loadPlanList(url)
    }
  }

  toggleDistributions(event) {
    const checkbox = event.currentTarget;
    const isChecked = checkbox.checked;
    if (isChecked) {
      this.showDistributions();
    } else {
      this.hideDistributions();
    }
  }

  // private

  showDistributions() {
    this.distributionsTarget.classList.remove('d-none');
  }

  hideDistributions() {
    this.distributionsTarget.classList.add('d-none');
  }

}