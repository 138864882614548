import { Controller } from "stimulus"
import consumer from '../channels/consumer'

export default class extends Controller {
  static get targets() {
    return [
      "note",
      "noteInChat",
      "noteContents",
      "noteChat",
      "noteModal",
      "checkbox",
      "loadDiv",
      "taskMessage",
      "approvalMessage",
      "planMessage",
      "activityMessage",
      "unreadBadge",
      "messageArea",
      "sendButton",
      "expandIcon",
      "userMessage"
    ]
  }

  connect() {
    var scrollingElement = (document.scrollingElement || document.body);
    scrollingElement.scrollTop = scrollingElement.scrollHeight;
    this.setMessage();
    this.initChannel();
    this.setSendButton();
  }

  disconnect() {
    this.reload_channel.unsubscribe();
  }

  //- chatChannel 및 대화방 내 메시지 뷰 설정
  initChannel() {
    let chat_room = document.getElementById('chatroom');
    const chat_room_id = chat_room.getAttribute('data-chat-id');
    const reload_channel_id = chat_room.getAttribute('data-reload-channel');
    const current_user_id = chat_room.getAttribute("data-current-user-id");
    const chat_controller = this;
    this.setMyMessageView(current_user_id);
    chat_room.scrollTop = chat_room.scrollHeight;
    this.channel = consumer.subscriptions.create('ChatChannel', {
      received(data) {
        if (chat_room != null && chat_room_id == data["chat_id"]) {
          let new_message = document.createElement('span');
          new_message.innerHTML = data["message_html"];

          // 메세지 중복 표시 방지 
          if (chat_room.innerHTML.indexOf(new_message.innerHTML) > 0) return;

          chat_room.appendChild(new_message);
          
          // 메세지 작성자가 본인인 경우 오른쪽 정렬
          let new_user_message = chat_controller.userMessageTargets.findLast(el => el.getAttribute('user-id'));
          if (new_user_message.getAttribute("user-id") == current_user_id) {
            new_user_message.classList.add('justify-content-end');
          }
          chat_room.scrollTop = chat_room.scrollHeight;
        }
      }
    });

    chat_controller.clearUnreadCount(chat_room);

    // 현재 챗에 새로운 unread message가 발생하면, 바로 읽음으로 상태 변경 요청을 보낸다.
    this.reload_channel = consumer.subscriptions.create({
      channel: 'ReloadChannel',
      id: reload_channel_id
    }, {
      received(data) {
        // 안 읽을 메시지가 있을 경우에만, 언제나 읽음 상태 변경 요청을 하면 무한 루프.
        if (data.raw_data.chat_unread_count > 0 || (!data.raw_data.is_action_required_to_read_mentions && data.raw_data.chat_unread_mention_count)) {
          chat_controller.clearUnreadCount(chat_room);
        }
      }
    })
  }

  //- 작성중이던 임시저장된 메시지가 있으면 메시지 작성창에 넣어주기
  setMessage() {
    var chat_room_id = document.getElementById('chatroom').getAttribute('data-chat-id');
    var temp_message = localStorage.getItem('chat_room_' + chat_room_id);
    if (temp_message != null && temp_message != "") {
      this.messageAreaTarget.value = temp_message;
      var rows = this.messageAreaTarget.value.split('\n');
      this.messageAreaTarget.setAttribute('rows', rows.length);
    }
  }

  //- 메시지 작성 중에 메시지 임시저장해주기
  autoSaveMessage() {
    var chat_room_id = document.getElementById('chatroom').getAttribute('data-chat-id');
    var message = this.messageAreaTarget.value;
    localStorage.setItem('chat_room_' + chat_room_id, message);
  }

  //- 대화방에 들어가면 해당 대화방의 안읽음 수를 clear
  clearUnreadCount(chat_room) {
    if (!chat_room)
      return;
    let url = window.location.pathname + "/clear_unread_count";
    Rails.ajax({
      type: "PUT",
      url: url
    });
  }

  //- 내 메시지는 오른쪽으로 정렬
  setMyMessageView(current_user_id) {
    this.userMessageTargets.forEach((el, i) => {
      if (el.getAttribute("user-id") == current_user_id) {
        el.classList.add('justify-content-end');
      }
    });
  }

  //- 메시지 내용이 있을 때에만 전송 버튼 활성화 : 메시지 입력 중
  enableSend() {
    if (this.hasMessageAreaTarget) {
      if (this.messageAreaTarget.value.length > 0) {
        this.sendButtonTarget.removeAttribute('disabled')
      } else {
        this.sendButtonTarget.setAttribute('disabled', true)
      }
    }
  }

  //- 메시지 내용이 있을 때에만 전송 버튼 활성화 : 대화방 진입 시
  setSendButton() {
    if (this.hasMessageAreaTarget) {
      if (this.messageAreaTarget.value.length > 0) {
        this.sendButtonTarget.removeAttribute('disabled')
      } else {
        this.sendButtonTarget.setAttribute('disabled', true)
      }
    }
  }

  //- 메시지 작성창에 입력된 메시지 전송
  sendMessage(e) {
    // Shift + Enter 줄바꿈
    if (e.key === "Enter") {
      if (e.shiftKey) return;
      e.preventDefault();
      if (e.altKey || e.metaKey || e.ctrlKey ) return;
    } else if (e.type === "keydown") {
      return;
    }

    let message = this.messageAreaTarget.value;
    let chatroom = document.getElementById('chatroom');
    let chat_id = chatroom.dataset.chatId;
    let alert_not_sended = chatroom.dataset.alertNotSended;
    let confirm_create_new_message = chatroom.dataset.confirmCreateNewMessage;
    if (!confirm(confirm_create_new_message)) return;
    if (this.channel.perform('speak', {
        body: message,
        chat_id: chat_id
      })) {
      this.messageAreaTarget.value = "";
      localStorage.removeItem('chat_room_' + chat_id);
    } else {
      alert(alert_not_sended);
    }
    this.enableSend();
  }

  //- 대화방 내에서 노트 열어보기 : 웹
  openNote() {
    let url = event.currentTarget.getAttribute("url");
    this.noteTarget.classList.remove("d-none");
    fetch(url)
      .then(response => response.text())
      .then(body => {
        this.noteContentsTarget.innerHTML = body
      });
  }

  //- 대화방 내에서 노트 열어보기 : 모바일 (모달)
  openNoteInMobile(event) {
    let url = event.currentTarget.getAttribute("url");
    fetch(url)
      .then(response => response.text())
      .then(body => {
        this.noteChatTarget.innerHTML = body;
        if (this.hasNoteModalTarget) {
          let modalController = this.application.getControllerForElementAndIdentifier(
            this.noteChatTarget,
            "modal"
          );
          modalController.open();
        }
      });
  }

  //- 대화방 내에서 열어본 노트 닫기 : 웹
  closeNote() {
    this.noteTarget.classList.add("d-none")
  }

  //- ‘숨겨진 메시지 보기’ 체크박스 체크된 경우 : 모든 리마인더 및 태스크, 결재 메시지 보여주기
  //- ‘숨겨진 메시지 보기’ 체크박스 체크되지 않은 경우 : 마지막 리마인더 및 태스크, 결재 메시지만 보여주기
  toggleMessages(event) {
    let messages = [];
    if (this.hasActivityMessageTarget)
      messages = messages.concat(this.activityMessageTargets);
    if (this.hasTaskMessageTarget)
      messages = messages.concat(this.taskMessageTargets);
    if (this.hasApprovalMessageTarget)
      messages = messages.concat(this.approvalMessageTargets);
    if (this.hasPlanMessageTarget)
      messages = messages.concat(this.planMessageTargets);

    let hidden_messages = messages.filter(message => message.classList.contains('bg-light'));
    if (event.currentTarget.checked) {
      hidden_messages.forEach((el, i) => {
        el.classList.remove('d-none');
      });
    } else {
      hidden_messages.forEach((el, i) => {
        el.classList.add('d-none');
      });
    }
  }

  //- ‘메시지 더 보기’ 시 메시지 30개씩 추가 로드
  load(event) {
    if (event.currentTarget.innerHTML.includes(`<i class="fa fa-spinner fa-pulse ml-2"></i>`)) {
      return;
    } else {
      event.currentTarget.innerHTML += `<i class="fa fa-spinner fa-pulse ml-2"></i>`;
    }
    let url = event.currentTarget.getAttribute("url");
    fetch(url)
      .then(response => response.text())
      .then(body => {
        this.loadDivTarget.outerHTML = body;
        if (this.checkboxTarget.checked) {
          let messages = [];
          if (this.hasActivityMessageTarget)
            messages = messages.concat(this.activityMessageTargets);
          if (this.hasTaskMessageTarget)
            messages = messages.concat(this.taskMessageTargets);
          if (this.hasApprovalMessageTarget)
            messages = messages.concat(this.approvalMessageTargets);
          if (this.hasPlanMessageTarget)
            messages = messages.concat(this.planMessageTargets);

          messages.filter(message => message.classList.contains('bg-light')).forEach((el, i) => {
            el.classList.remove('d-none');
          });
        }
      });
  }

  //- 메시지 입력창에서 여러 줄의 메시지 작성 시 최대 5줄까지 자동으로 메시지 입력창의 높이를 키워주기
  adjustMessageArea(event) {
    var rows = event.currentTarget.value.split('\n');
    var scrollingElement = (document.scrollingElement || document.body);
    var scrollTop;
    let mainviewHeight = 0;
    if (rows.length > 1 && rows.length < 6) {
      if (rows.length == event.currentTarget.getAttribute('rows')) {
        scrollTop = 0
      } else {
        if (rows.length > event.currentTarget.getAttribute('rows')) {
          scrollTop = 25
        } else {
          document.getElementById('mainview')?.childNodes?.forEach(el => {
            mainviewHeight += el.clientHeight;
          })
          if (document.getElementById('strategyList')?.clientHeight > mainviewHeight) {
            scrollTop = -25
          } else {
            scrollTop = 0
          }
        }
      }
      event.currentTarget.setAttribute('rows', rows.length);
      scrollingElement.scrollTop += scrollTop;
    }
  }

  //- 메시지 입력창에서 여러 줄의 메시지 작성 시 최대 5줄까지 자동으로 메시지 입력창의 높이를 키워주기
  adjustMessageAreaOnMobile(event) {
    var rows = event.currentTarget.value.split('\n');
    var scrollingElement = (document.scrollingElement || document.body);
    if (rows.length > 1 && rows.length < 6) {
      event.currentTarget.setAttribute('rows', rows.length);
      scrollingElement.scrollTop = scrollingElement.scrollHeight;
    }
  }

  //- 버튼으로 메시지 입력창을 확장/축소하기
  expandMessageArea() {
    var scrollingElement = (document.scrollingElement || document.body);
    if (document.getElementsByClassName('fa-expand-alt').length > 0) {
      this.expandIconTarget.classList.remove('fa-expand-alt');
      this.expandIconTarget.classList.add('fa-compress-alt');
      this.messageAreaTarget.setAttribute('rows', 40);
      this.messageAreaTarget.setAttribute('data-action', [
        "keydown->chat#sendMessage",
        "input->chat#autoSaveMessage",
        "input->chat#enableSend",
        "input->autocomplete#showList"
      ].join(" "));
    } else {
      var rows = this.messageAreaTarget.value.split('\n');
      this.expandIconTarget.classList.remove('fa-compress-alt');
      this.expandIconTarget.classList.add('fa-expand-alt');
      this.messageAreaTarget.setAttribute('rows', rows.length);
      this.messageAreaTarget.setAttribute('data-action', [
        "keyup->chat#adjustMessageArea",
        "keydown->chat#sendMessage",
        "input->chat#autoSaveMessage",
        "input->chat#enableSend",
        "input->autocomplete#showList"
      ].join(" "));
    }
    scrollingElement.scrollTop = scrollingElement.scrollHeight;
  }

}