import { Controller } from "stimulus"

export default class extends Controller {
    static get targets() {
        return [ "likeButton", "unlikeButton", "likeCol", "likeBadge" ]
    }

    getMetaValue(name) {
        const element = document.head.querySelector(`meta[name="${name}"]`)
        return element.getAttribute("content")
    }

    //- ‘관심있어요’에 추가 및 ‘관심있어요’에서 제거하기
    like() {
        let url = this.data.get("url");
        let tooltip_url = this.data.get("tooltip-url");
        fetch(url, {
            method: 'put',
            credentials: "same-origin",
            headers: {
                "X-CSRF-Token": this.getMetaValue("csrf-token")
            }
        })
            .then(response => response.text())
            .then(body => {
                var likeCount = parseInt(body.split('-')[0]);
                alert(body.split('-')[2]);
                if (body.indexOf("save") !== -1) {
                    this.unlikeButtonTarget.classList.add('d-none');
                    this.likeButtonTarget.classList.remove('d-none');
                    if (this.hasLikeColTarget) {
                        this.like_tooltip(tooltip_url,this.likeColTarget,likeCount);
                    }
                } else {
                    this.unlikeButtonTarget.classList.remove('d-none');
                    this.likeButtonTarget.classList.add('d-none');
                    if (this.hasLikeColTarget) {
                        this.like_tooltip(tooltip_url,this.likeColTarget,likeCount);
                    }
                }
            });
    }

    //- 관심 있는 사용자 리스트 툴팁 띄우기
    like_tooltip(url,target,likeCount) {
        fetch(url)
            .then(response => response.text())
            .then(body => {
                target.innerHTML = body;
                window.setTooltip();
                if (likeCount === 0) {
                    target.classList.add('d-none');
                } else {
                    target.classList.remove('d-none');
                }
            });
    }
}