/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

console.debug('Hello World from Webpacker')

require("@rails/ujs").start()
require("turbolinks").start()
require("channels")
require("chartkick").use(require("highcharts"))
require.context('../images', true)

require("trix")
require("@rails/actiontext")
require('tui-calendar'); 
require('tui-calendar/dist/tui-calendar.css');
require('tui-date-picker/dist/tui-date-picker.css');
require('tui-time-picker/dist/tui-time-picker.css');

import "@stimulus/polyfills"
import "whatwg-fetch"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import "./stylesheets/application.scss"
import 'bootstrap'
import 'bootstrap-select'
import 'jquery.caret'
import 'sticky-table-headers'
import 'at.js'
import "animate.css"
import "./stylesheets/application"
import "semantic/dist/semantic.js"

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

window.jQuery = $;
window.$ = $;

window.onload = () => {
  window.setDeviceData();
};

document.addEventListener('turbolinks:load', () => {
  $("[data-toggle='tooltip']").tooltip();
  $('.custom-select').selectpicker();
  $(".sticky_table_headers").stickyTableHeaders();
});

document.addEventListener('turbolinks:before-cache', () => {
  $('.custom-select').selectpicker('destroy').addClass('custom-select')
});

window.getMetaValue = (name) => {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute("content")
}
