import { Controller } from "stimulus"
import consumer from '../channels/consumer'

export default class extends Controller {
  static get targets() {
    return [ "loadedHtml" ]
  }

  connect() {
    let reload_channel = this.data.get("reload-channel");
    let controller = this;
    if (reload_channel != undefined) {
      consumer.subscriptions.create({
        channel: 'ReloadChannel',
        id: reload_channel
      }, {
        received(data) {
          if (data["content"] === "update") {
            controller.load()
          }
        }
      })
    }
  }

  load() {
    fetch(this.data.get("url"))
      .then(response => response.text())
      .then(body => {
        this.loadedHtmlTarget.innerHTML = body
      })
  }

}