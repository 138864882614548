import { Controller } from "stimulus"

export default class extends Controller {
    static get targets() {
        return [ "checkbox", "oneCheckbox", "loginId" ]
    }

    //- 유저의 프로필과 이름 부분을 클릭해도 해당하는 체크박스가 클릭되도록
    check(event) {
        if (this.checkboxTarget == event.target) return;
        this.checkboxTarget.click();
    }

    //- 회원가입 시 아이디를 이메일 형식으로 입력하는 경우 이메일 입력 필드에도 해당 이메일을 자동으로 입력해주기
    recommendEmail(event) {
        let login_id = this.loginIdTarget.value;
        var email_format = /\S+@\S+\.\S+/;
        if (event.currentTarget.value.length === 0 && login_id.match(email_format)) {
            event.currentTarget.value = login_id
        }
    }
}