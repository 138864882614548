import { Controller } from "stimulus"

export default class extends Controller {

    getMetaValue(name) {
        const element = document.head.querySelector(`meta[name="${name}"]`)
        return element.getAttribute("content")
    }

    //- 유저가 타임존 변경 시
    changeTimezone(event) {
        let base_url = event.currentTarget.getAttribute("url");
        let timezone = event.currentTarget.value;

        let url = base_url + "?time_zone=" + escape(timezone);

        fetch(url, {
            method: 'put',
            credentials: "same-origin",
            headers: {
                "X-CSRF-Token": this.getMetaValue("csrf-token")
            }
        })
            .then(response => response.text())
            .then(body => {
                if (body == "OK") {
                    location.href = window.location.pathname;
                }
            });
    }


}