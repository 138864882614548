import { Controller } from "stimulus";

export default class extends Controller {
  dragstart(event) {
    event.dataTransfer.setData("application/drag-key", event.target.getAttribute("data-order"));
    event.dataTransfer.effectAllowed = "move";
  }

  dragover(event) {
    event.preventDefault();
    return true;
  }

  dragenter(event) {
    event.preventDefault();
  }

  drop(event) {
    let order = event.dataTransfer.getData("application/drag-key");
    const dropTarget = event.target.closest(`[data-target*="drag-item.column"]`);
    const draggedItem = this.element.querySelector(`[data-order='${order}']`);

    const positionComparison = dropTarget.compareDocumentPosition(draggedItem);
    let position;
    if (positionComparison & 4) {
      position = 'beforebegin';
    } else if (positionComparison & 2) {
      position = 'afterend';
    }

    if (position) {
      if (this.element.tagName === "TABLE") {
        if ((dropTarget?.parentElement != draggedItem?.parentElement) || !dropTarget?.dataset?.order) {
          event.preventDefault();
          return;
        }
        let itemIndex = [...draggedItem.parentElement.childNodes].indexOf(draggedItem);
        let targetIndex = [...dropTarget.parentElement.childNodes].indexOf(dropTarget);
        dropTarget.insertAdjacentElement(position, draggedItem);
        this.element.querySelectorAll("tbody tr").forEach((row) => {
          let item = row.querySelectorAll('th, td')[itemIndex];
          let target = row.querySelectorAll('th, td')[targetIndex];
          target.insertAdjacentElement(position, item);
        });
      } else {
        dropTarget.insertAdjacentElement(position, draggedItem);
      }
    }
    event.preventDefault();
  }

  dragend(event) {
    this.element.querySelectorAll(`[data-order]`).forEach((el, i) => {
      el.dataset.order = i;
    });

    if (this.element.tagName === "TABLE") {
      let indicatorController = this.application.getControllerForElementAndIdentifier(
        this.element.closest('[data-controller*=indicator]'),
        "indicator"
      );

      this.element.querySelectorAll("tr").forEach((row) => {
        indicatorController?.orderReset(row);
      });
    }
  }
}