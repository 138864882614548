import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return []
  }

  //- 날짜 선택 input에 캘린더에서 선택한 날짜 넣어주기
  changeDuration(event) {
    document.getElementById('start_date').removeAttribute('readonly');
    document.getElementById('start_date').value = event.currentTarget.getAttribute("start");
    document.getElementById('start_date').setAttribute('readonly', true);
    document.getElementById('end_date').removeAttribute('readonly');
    document.getElementById('end_date').value = event.currentTarget.getAttribute("end");
    document.getElementById('end_date').setAttribute('readonly', true);
  }

  //- 분석에 기간 변경
  selectDate() {
    let parseUrl = new URL(`${location.origin}${window.location.pathname}`);
    if (!parseUrl.pathname.includes('analysis')) {
      parseUrl.searchParams.set('tab', 'analysis');
    }
    parseUrl.searchParams.set('start', document.getElementById('start_date').value);
    parseUrl.searchParams.set('end', document.getElementById('end_date').value);
    location.href = parseUrl;
  }
}