import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ["list"]
  }

  connect() {
    let checked_group_users = [...document.querySelectorAll('input[type=checkbox][name^=group_users]')].filter(el => el.checked)
    document.querySelectorAll('input[type=checkbox][name^=favorite_folders]').forEach(el => {
      if (checked_group_users.length > 1) {
        el.dataset.target = "checkbox.checkbox";
        el.dataset.action = "checkbox#limit";
      } else if (checked_group_users.length > 0) {
        el.checked = checked_group_users[0].closest('div[data-search-favorite-folder-id]').dataset.searchFavoriteFolderId.split(", ").includes(el.value);
      }
    })
  }

  addToFavoriteFolder(event) {
    event.preventDefault();
    let target = event.currentTarget || event.target;
    let formData = new FormData();
    [...document.querySelectorAll('input[type=checkbox][name^=favorite_folders], input[type=checkbox][name^=group_users]')].filter(el => el.checked)
      .forEach(el => {
        formData.set(el.name, el.value);
      });
    let url = target.getAttribute('url');
    fetch(url, {
        method: 'PUT',
        credentials: "same-origin",
        headers: {
          "X-CSRF-Token": window.getMetaValue("csrf-token")
        },
        body: formData,
      })
      .then(response => {
        if (response.ok) {
          location.reload();
        }
      })
  }

  new(event) {
    let target = event.currentTarget || event.target;
    let url = target.getAttribute('url');
    fetch(url)
      .then(response => response.text())
      .then(body => {
        this.listTarget.innerHTML += body;
      });
  }

  edit(event) {
    let target = event.currentTarget || event.target;
    let url = target.getAttribute('url');
    let listItem = target.closest('li');
    fetch(url)
      .then(response => response.text())
      .then(body => {
        listItem.outerHTML = body;
      });
  }

  submit(event) {
    let target = event.currentTarget || event.target;
    const [data, status, xhr] = event.detail;
    target.parentElement.outerHTML = xhr.response;
  }

  revert(event) {
    let target = event.currentTarget || event.target;
    const form = target.form;
    let _method = new FormData(form).get('_method');
    let listItem = target.closest('li');

    if (_method == 'patch') {
      let url = form.action;
      fetch(url)
        .then(response => response.text())
        .then(body => {
          listItem.outerHTML = body;
        });
    } else {
      listItem.outerHTML = "";
    }

  }

  destroy(event) {
    let target = event.currentTarget || event.target;
    if (!confirm(target.dataset.confirmMessage)) return;

    let url = target.getAttribute('url');
    let listItem = target.closest('li');
    fetch(url, {
        method: 'DELETE',
        credentials: "same-origin",
        headers: {
          "X-CSRF-Token": window.getMetaValue("csrf-token")
        }
      })
      .then(response => {
        if (response.ok)
          listItem.outerHTML = "";
      });
  }
}