import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [
      "card",
      "checkbox",
      "favoriteFolderSelect",
      "mapSelect",
      "hqSelect",
      "teamSelect",
      "searchForm",
      "keyword",
      "result",
      "searchButton",
    ]
  }

  //- Live search
  liveSearch(event) {
    this.cardTargets
      .filter(el => el.dataset.searchInfo)
      .filter(el => {
        const condition = el.dataset.searchInfo.search(new RegExp(event.currentTarget.value, 'i')) > -1;
        this.setWhetherOrNotToDisplayElementByCondition(el, condition);
        return condition;
      });
  }

  //- 모든 체크박스의 체크를 하거나 제외하거나
  checkAll(event) {
    const visible_cards = this.cardTargets.filter(card => !card.classList.contains('d-none') && card.querySelector('input[type=checkbox]'));
    visible_cards.forEach((el, i) => {
      el.querySelector('input[type=checkbox]').checked = event.currentTarget.checked;
    });
  }

  //- 즐겨찾기 폴더 선택시 해당 폴더에 있는 사용자만 보여주기
  selectFavoriteFolder(event) {
    this.cardTargets
      .filter((el, i) => {
        const condition = event.currentTarget.value == 0 ||
          el.dataset.searchFavoriteFolderId?.split(", ").includes(event.currentTarget.value);
        this.setWhetherOrNotToDisplayElementByCondition(el, condition);
        return condition;
      });
  }

  //- 맵 선택 시 해당 맵 및 해당 본부의 팀에 소속된 사용자만 보여주기
  selectMap(event) {
    let form_url = this.element.dataset.searchFormUrl;
    let map_id = event.currentTarget.value;
    if (map_id == 0) {
      this.fetch_url(`${form_url}`, this.searchFormTarget);
    } else {
      this.fetch_url(`${form_url}?map_id=${map_id}`, this.searchFormTarget);
    }
    
    this.cardTargets
      .filter((el, i) => {
        const condition = map_id == 0 ||
          JSON.parse(el.dataset.searchMapIds)?.includes(parseInt(map_id));
        this.setWhetherOrNotToDisplayElementByCondition(el, condition);
        return condition;
      });
  }

  //- 본부 선택 시 해당 본부 및 해당 본부의 팀에 소속된 사용자만 보여주기
  selectHq(event) {
    let form_url = this.element.dataset.searchFormUrl;
    let map_id,
        hq_id = event.currentTarget.value;
    if (hq_id == 0) {
      if (this.hasMapSelectTarget) {
        map_id = this.mapSelectTarget.value;
        this.fetch_url(`${form_url}?map_id=${map_id}`, this.searchFormTarget);
      } else {
        this.fetch_url(`${form_url}`, this.searchFormTarget);
      }
    } else {
      this.fetch_url(`${form_url}?hq_id=${hq_id}`, this.searchFormTarget);
    }
    
    this.cardTargets
      .filter((el, i) => {
        const condition = (!map_id && hq_id == 0) ||
                          (map_id && JSON.parse(el.dataset.searchMapIds)?.includes(parseInt(map_id))) ||
                          (hq_id && JSON.parse(el.dataset.searchHqIds)?.includes(parseInt(hq_id)));
        this.setWhetherOrNotToDisplayElementByCondition(el, condition);
        return condition;
      });
  }

  //- 팀 선택 시 해당 팀에 소속된 사용자만 보여주기
  selectTeam(event) {
    let form_url = this.element.dataset.searchFormUrl;
    let hq_id,
        team_id = event.currentTarget.value;
    if (team_id == 0) {
      if (this.hasHqSelectTarget) {
        hq_id = this.hqSelectTarget.value;
        this.fetch_url(`${form_url}?hq_id=${hq_id}`, this.searchFormTarget);
      }
    } else {
      this.fetch_url(`${form_url}?team_id=${team_id}`, this.searchFormTarget);
    }
    
    this.cardTargets
      .filter((el, i) => {
        const condition = (hq_id && JSON.parse(el.dataset.searchHqIds)?.includes(parseInt(hq_id))) ||
                          (team_id && JSON.parse(el.dataset.searchTeamIds)?.includes(parseInt(team_id)));
        this.setWhetherOrNotToDisplayElementByCondition(el, condition);
        return condition;
      });
  }

  search(event) {
    this.resultTarget.querySelector('.search__result__none').classList.add('d-none');
    this.resultTarget.querySelector('.search__result__list').classList.add('d-none');

    const keyword = this.keywordTarget.value;
    const url = `${this.element.dataset.url}?id=${keyword}`;
    this.messages = JSON.parse(this.data.get('messages'));

    if (!keyword) {
      return alert(this.messages.warn_no_search_word);
    }
    if (this.isSearching) {
      return;
    }

    this.isSearching = true;
    this.searchButtonTarget.disabled = true;
    fetch(url)
      .then(response => response.json())
      .then(body => body.result)
      .then(result => {
        this.searchButtonTarget.disabled = false;
        this.isSearching = false;

        if (result) {
          const type = this.data.get('type');
          this[type](result);
          this.resultTarget.querySelector('.search__result__none').classList.add('d-none');
          this.resultTarget.querySelector('.search__result__list').classList.remove('d-none');
        } else {
          this.resultTarget.querySelector('.search__result__list').classList.add('d-none');
          this.resultTarget.querySelector('.search__result__none').classList.remove('d-none');
        }
      })
      .catch(error => {
        console.debug(error);
        this.searchButtonTarget.disabled = false;
        this.isSearching = false;
      });
  }

  setActivity({
    id,
    title,
    map,
    strategy,
    detailed_strategy,
    project,
    plan,
    issue
  }) {
    this.messages = this.messages || JSON.parse(this.data.get('messages'));
    const linkTo = `/maps/${map.id}/activities/${id}`;
    const itemTemplate = `
            <a class="search__result__item list-group-item" target="_blank" href="${linkTo}">
                <div class="item__title h5">${title}</div>
                <div class="item__info mt-1">
                    <span class="item__info__label">${this.messages.map}: </span>
                    <span class="item__info__content item__map">${map.name}</span>
                </div>
                <div class="item__info mt-1">
                    <span class="item__info__label">${this.messages.strategy}: </span>
                    <span class="item__info__content item__strategy">${strategy.name}</span>
                </div>
                <div class="item__info mt-1">
                    <span class="item__info__label">${this.messages.detailed_strategy}: </span>
                    <span class="item__info__content item__detailed_strategy">${detailed_strategy.name}</span>
                </div>
                <div class="item__info mt-1">
                    <span class="item__info__label">${this.messages.project}: </span>
                    <span class="item__info__content item__project">${project.name}</span>
                </div>
                <div class="item__info mt-1 ${(plan) ? '' : 'd-none'}">
                    <span class="item__info__label">${this.messages.plan}: </span>
                    <span class="item__info__content item__plan">${plan?.name}</span>
                </div>
                <div class="item__info mt-1 ${(issue) ? '' : 'd-none'}">
                    <span class="item__info__label">${this.messages.issue}: </span>
                    <span class="item__info__content item__issue">${issue?.name}</span>
                </div>
            </a>
        `;

    this.resultTarget.querySelector('.search__result__list').innerHTML = itemTemplate;
  }

  //- 검색 조건에 따른 element 표시 여부 
  setWhetherOrNotToDisplayElementByCondition(el, condition) {
    if (condition) {
      el.classList.remove("d-none");
    } else {
      el.classList.add("d-none");
    }
  }

  fetch_url(url, target) {
    fetch(url)
      .then(response => response.text())
      .then(body => {
        target.outerHTML = body;
      });
  }

}