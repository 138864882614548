import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [
      "teamPlans",
      "teamIssues",
      "newMapButton",
      "formDiv",
      "noticeArchive",
      "toggleButton",
      "toggleIcon",
      "detailedStrategy",
      "angleIcon",
    ]
  }

  //- 대시보드에서 팀/프로젝트별 계획/이슈 목록에서 리스트 보여주고 숨기기
  openTeamPlans(event) {
    let url = this.data.get("dataUrl");
    let target = event.currentTarget || event.target;

    [...document.getElementsByClassName("opened-team")].forEach((el) => {
      el.innerHTML = "";
      el.classList.add('d-none');
      el.classList.remove('opened-team');
    });

    [...document.getElementsByClassName("open-content-icon")].forEach((el) => {
      if ((el !== event.target) && el.classList.contains("fa-angle-up")) {
        el.classList.replace("fa-angle-up", "fa-angle-down");
        el.classList.add('animated', 'rotateIn');
      }
    });
    
    if(this.hasAngleIconTarget){
      if (target.classList.contains("card-open")) {
        fetch(url)
          .then(response => response.text())
          .then(body => {
            this.teamPlansTarget.innerHTML = body;
            this.teamPlansTarget.classList.add('opened-team');
            this.teamPlansTarget.classList.remove('d-none');
            this.angleIconTarget.classList.replace("fa-angle-down", "fa-angle-up");
            target.classList.replace("card-open", "card-close");
          });
      } else {
        this.angleIconTarget.classList.replace("fa-angle-up", "fa-angle-down");
        target.classList.replace("card-close", "card-open");
      }
      target.addEventListener('animationend', () => {
        target.classList.remove('animated', 'rotateIn')
      });
    }
    }

  //- 전략맵 / 이슈맵 / 통제맵 / okr맵 / iso맵 / kpi맵 form 리로드
  selectForm(event) {
    this.newMapButtonTargets.forEach((el) => {
      el.classList.add('inactive');
    });
    let url = event.currentTarget.getAttribute("url");

    event.currentTarget.classList.replace('btn-outline-info', 'btn-info');

    if (document.querySelectorAll('.btn-info').length > 1) {
      document.querySelectorAll('.btn-info').forEach((el, i) => {
        if (el != event.target) {
          el.classList.replace('btn-info', 'btn-outline-info');
        }
      });
    }

    fetch(url)
      .then(response => response.text())
      .then(body => {
        this.formDivTarget.innerHTML = body;
        event.target.classList.remove('inactive');
      });
  }

  //- 맵 계획 목록에서 '팀별로 묶어서 보기' 시 팀의 계획 로드 / 숨기기
  openGroupedPlans() {
    let url = this.data.get("dataUrl");


    if (this.teamPlansTarget.classList.contains('opened-team')) {
      this.teamPlansTarget.classList.add('d-none');
      this.teamPlansTarget.classList.remove('opened-team');
    } else {
      if (document.getElementsByClassName("opened-team").length > 0) {
        document.getElementsByClassName("opened-team")[0].classList.add('d-none');
        document.getElementsByClassName("opened-team")[0].classList.remove('opened-team');
      }
      fetch(url)
        .then(response => response.text())
        .then(body => {
          this.teamPlansTarget.innerHTML = body;
          window.setTooltip();
        });
      this.teamPlansTarget.classList.remove('d-none');
      this.teamPlansTarget.classList.add('opened-team');
    }
  }


  //- 맵 이슈 목록에서 '팀별로 묶어서 보기' 시 팀의 이슈 로드 / 숨기기
  openGroupedIssues() {
    let url = this.data.get("dataUrl");

    if (this.teamIssuesTarget.classList.contains('opened-team')) {
      this.teamIssuesTarget.classList.add('d-none');
      this.teamIssuesTarget.classList.remove('opened-team');
    } else {
      if (document.getElementsByClassName("opened-team").length > 0) {
        document.getElementsByClassName("opened-team")[0].classList.add('d-none');
        document.getElementsByClassName("opened-team")[0].classList.remove('opened-team');
      }
      fetch(url)
        .then(response => response.text())
        .then(body => {
          this.teamIssuesTarget.innerHTML = body
          window.setTooltip();
        });
      this.teamIssuesTarget.classList.remove('d-none');
      this.teamIssuesTarget.classList.add('opened-team');
    }
  }

  //- 그룹 관리자가 맵 편집할 때, 접근불가인 프로젝트를 숨김 처리할 것인지, 말 것인지 선택 시 안내 문구 보여주고 숨기기
  showNoticeArchive(event) {
    if (event.currentTarget.checked) {
      this.noticeArchiveTarget.classList.remove('d-none')
    } else {
      this.noticeArchiveTarget.classList.add('d-none')
    }
  }

  //- 그룹 관리자가 프로젝트 편집 시, 프로젝트 목록을 모두 한꺼번에 펼치고 접기
  toggleAllProjects() {
    let before;
    if (this.toggleIconTarget.classList.contains("down")) {
      before = "down";
      this.toggleIconTarget.classList.remove("down");
      this.toggleIconTarget.classList.add("up");
    } else {
      before = "up";
      this.toggleIconTarget.classList.remove("up");
      this.toggleIconTarget.classList.add("down");
    }
    [...this.detailedStrategyTargets].forEach((el) => {
      const detailedStrategyController = this.application.getControllerForElementAndIdentifier(
        el,
        "detailed-strategy"
      )
      if (detailedStrategyController.toggleIconTarget.classList.contains(before))
        detailedStrategyController.toggleProjects();
    });
  }
}