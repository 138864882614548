import { Controller } from "stimulus";

export default class extends Controller {
  static get targets() {
    return [
      "form",
      "comment",
      "loadDiv",
      "approver",
      "approverList",
      "attributeForm",
      "userApprovals",
      "newForm",
      "nextApprover",
      "nextApprovers",
    ]
  }

  connect() {
    if (this.approverTargets.length > 0) {
      //- 결재 경로 설정에서 이미 결재 완료한 사용자가 있으면 해당 select 에서 disabled 될 사용자 설정
      this.approverTargets.forEach((el, i) => {
        if (i == 0) return;
        this.setDisabledOption(el, this.approverTargets[i - 1].value);
      })
    } else if (this.hasApproverListTarget && this.hasAttributeFormTarget) {
      //- 새 결재 생성 모달을 처음 열었을 떄는 approver 를 선택하지 않은 상태이므로 form 을 숨기기
      this.attributeFormTarget.classList.add('d-none');
    }
    if (this.hasNewFormTarget) {
      this.approverListTarget.setAttribute("hidden", true);
    }
  }

  //- 유저 프로필 화면에서 결재 만들기
  loadNewForm() {
    var user_id = this.newFormTarget.getAttribute("user-id");
    document.getElementById("approvers_" + user_id).setAttribute("checked", true);
    this.attributeFormTarget.classList.remove("d-none")
  }

  //- 결재 경로 설정에 승인자 추가
  addSelect() {
    var newDiv = document.createElement("div");
    newDiv.className = "row justify-content-between my-1 mx-0"
    newDiv.innerHTML = this.nextApproverTarget.innerHTML;
    if (newDiv.hasChildNodes()) {
      var children = newDiv.childNodes;
      for (var i = 0; i < children.length; i++) {
        if (children[i].tagName == "SELECT") {
          children[i].setAttribute("required", true);
          children[i].setAttribute("data-target", "approval.approver");
          children[i].setAttribute("data-action", "approval#changeNextDisabledApprover");
          this.setDisabledApprover(children[i])
        }
      }
    }
    this.nextApproversTarget.appendChild(newDiv);
    this.toggleUserList();
  }

  //- 결재 경로 설정에서 승인자 제거
  removeSelect(event) {
    let row, button, selected_el, selected_index, form;
    if (event.currentTarget.tagName == "BUTTON") {
      button = event.target;
    } else if (event.currentTarget.tagName == "I") {
      button = event.currentTarget.parentNode;
    }
    row = button.parentNode;

    if (this.nextApproversTarget.hasChildNodes()) {
      [...this.nextApproversTarget.childNodes].reverse().forEach((el, i, arr) => {
        form = button.form;
        if (el === row) {
          selected_el = el;
          selected_index = i;
          if (el === this.nextApproversTarget.lastChild) {
            form = button.form;
            const hidden = document.createElement('input');
            hidden.name = this.nextApproversTarget.lastChild.firstChild.name;
            hidden.id = this.nextApproversTarget.lastChild.firstChild.id;
            hidden.type = 'hidden';
            form.appendChild(hidden);
          }
        } else if ((selected_index == undefined || i < selected_index) && i < 3) {
          if (el === this.nextApproversTarget.lastChild) {
            const hidden = document.createElement('input');
            hidden.name = this.nextApproversTarget.lastChild.firstChild.name;
            hidden.id = this.nextApproversTarget.lastChild.firstChild.id;
            hidden.type = 'hidden';
            form.appendChild(hidden);
          }
          el.firstChild.name = arr[i + 1].firstChild.name;
          el.firstChild.id = arr[i + 1].firstChild.id;
        }
      })
      this.nextApproversTarget.removeChild(selected_el);
    }

    this.toggleUserList();
  }

  //- 결재 경로 설정에서 팀 선택했던 select 변경 시 다음 select 에서 disabled 될 팀 변경
  changeNextDisabledApprover(event) {
    this.approverTargets.forEach((el, i) => {
      if (event.target === el) {
        if (!(event.currentTarget.name && event.currentTarget.name.includes('next_approvers'))) {
          let hidden = [...document.querySelectorAll('input[type=hidden]')].find((el) => el.name.includes(`next_approvers[${i + 1}]`));
          if (hidden) {
            event.currentTarget.name = hidden.name;
            event.currentTarget.id = hidden.id;
            hidden.remove();
          } else {
            event.currentTarget.name = `next_approvers[${i + 1}][${event.currentTarget.value}]`;
            event.currentTarget.id = `next_approvers_${i + 1}_${event.currentTarget.value}`;
          }
        }
        let select = this.approverTargets[i + 1]
        if (select)
          this.setDisabledOption(select, event.currentTarget.value)
      }
    })
    this.toggleUserList();
  }

  //- 결재 경로 설정에서 사용자 추가 시 해당 select 에서 disabled 될 사용자 설정
  setDisabledApprover(select) {
    let index = this.approverTargets.length - 1
    if (index >= 0)
      this.setDisabledOption(select, this.approverTargets[index].value);
  }

  //- select 에서 disabled 될 option 설정
  setDisabledOption(select, value) {
    for (var i = 0; i < select.options.length; i++) {
      if (select.options[i].value === value) {
        select.options[i].setAttribute('disabled', 'disabled')
      } else {
        select.options[i].removeAttribute('disabled')
      }
    }
  }

  //- 결재 상태 변화 : 검토 중, 좀 부족, 다했음, 확인 시
  changeStatus(event) {
    let currentTarget = event.currentTarget || event.target;
    currentTarget.setAttribute('disabled', true);
    const [data, status, xhr] = event.detail;
    let { message } = JSON.parse(xhr.response);
    if (message) {
      alert(message);
      location.reload();
    }
  }

  //- 결재 목록에서 더보기로 30개씩 추가 로드
  load(event) {
    let url = event.currentTarget.getAttribute("url");
    fetch(url)
      .then(response => response.text())
      .then(body => {
        this.loadDivTarget.outerHTML = body;
        event.currentTarget.classList.add('d-none')
      });
  }

  //- 새 결재 생성 시 approver 를 선택해야 form 보여주고, 아니면 숨기기
  toggleUserList() {
    if (this.approverTargets.filter(el => el.value).length > 0) {
      this.attributeFormTarget.classList.remove('d-none')
    } else {
      this.attributeFormTarget.classList.add('d-none')
    }
  }

  //- 맵 결재 목록에서 결재 받은 사람으로 묶어서 보기 시 받은 사람별로 결재 로드
  openGroupedTasks() {
    let url = this.data.get("dataUrl");
    if (this.userApprovalsTarget.classList.contains('opened-approver')) {
      this.userApprovalsTarget.classList.add('d-none');
      this.userApprovalsTarget.classList.remove('opened-approver');
    } else {
      if (document.getElementsByClassName("opened-approver").length > 0) {
        document.getElementsByClassName("opened-approver")[0].classList.add('d-none');
        document.getElementsByClassName("opened-approver")[0].classList.remove('opened-approver');
      }

      fetch(url)
        .then(response => response.text())
        .then(body => {
          this.userApprovalsTarget.innerHTML = body;
          window.setTooltip();
        });
      this.userApprovalsTarget.classList.remove('d-none');
      this.userApprovalsTarget.classList.add('opened-approver');
    }
  }
}