import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  connect() {
    // $(this.checkboxTargets).checkbox();
  }

  //- 선택할 수 있는 체크박스를 하나로 제한
  limit(event) {
    const controller = this;
    let target = event.currentTarget || event.target;
    let checked_one = this.checkboxTargets.filter(checkbox => checkbox.checked);
    if (checked_one.length > 1) {
      const accordion_controller = controller.application.getControllerForElementAndIdentifier(
        target.closest(".accordion"),
        "accordion"
      )
      checked_one.forEach((el, i) => {
        if (accordion_controller)
          accordion_controller.close();
        el.checked = false;
      });
      if (accordion_controller)
        accordion_controller.open();
      target.checked = true;
    }
  }
}