import { Controller } from "stimulus"

export default class extends Controller {
    static get targets() {
        return [ "select", "projectTeam", "headerAndBreadcrumb", "checkbox", "selectForBulkUpdate" ]
    }

    getMetaValue(name) {
        const element = document.head.querySelector(`meta[name="${name}"]`)
        return element.getAttribute("content")
    }

    // 프로젝트-팀의 권한 변경
    changeAccess(event) {
        const button = event.target;
        let url = button.getAttribute("url");
        this.updateViewBeforeRequest(event);
        const requestBody = this.projectTeamTargets.map(el => {
            const newDiv = document.createElement("div");
            newDiv.classList.add("col-auto", "h5", "d-flex", "align-items-center", "mb-0");
            newDiv.innerHTML = `<i class="fa fa-spinner fa-spin"></i>`;
            el.append(newDiv);
            const projectTeamId = el.getAttribute("project-team-id");
            const projectTeamRole = document.getElementById(`role_id_${projectTeamId}`).value;
            return { id: parseInt(projectTeamId), role_id: projectTeamRole };
        });

        fetch(url, {
            method: 'PUT',
            credentials: "same-origin",
            headers: {
                "X-CSRF-Token": this.getMetaValue("csrf-token"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ body: requestBody })
        })
            .then(response => {
                if (response.ok) {
                    response.json().then(body => {
                        this.updateViewAfterResponse(body.project_teams);
                        document.getElementById('permissionWarning').classList.add('d-none');
                        button.removeAttribute('disabled');
                        this.headerAndBreadcrumbTarget.innerHTML = body.header_and_breadcrumb;
                    });
                } else {
                    response.json().then(body => {
                        alert(body.message);
                    })
                }
            })
    }

    checkAll(event) {
        const checkpoint = event.target.closest('.permission__checkpoint')
        checkpoint.querySelectorAll('.permission__checkpoint__checkbox, .permission__checkbox').forEach(el => el.checked = event.target.checked);

        this.checkClosest(checkpoint);
        this.unselect();
    }

    check(event) {
        const checkpoint = event.target.closest('.permission__checkpoint');
        const checkpoint_checkbox = checkpoint.querySelector('.permission__checkpoint__checkbox');
        const current_point_targets = checkpoint.querySelectorAll('.permission__checkbox');
        if (Array.from(current_point_targets).every(el => el.checked)) {
            checkpoint_checkbox.checked = true;
        } else {
            checkpoint_checkbox.checked = false;
        }

        this.checkClosest(checkpoint);
        this.unselect();
    }

    selectChecked(event) {
        const role_id = event.target.value;
        if (!role_id) {
            return;
        }

        const select_tag_ids = Array.from(document.querySelectorAll('.permission__checkbox'))
            .filter(t => t.checked == true)
            .map(t => {
                const project_team_id = t.id.split('_').at(-1);
                const select_tag_id = `#role_id_${project_team_id}`;
                return select_tag_id;
        });
        if (select_tag_ids.length == 0) {
            return;
        }
        $(select_tag_ids.join(',')).dropdown('set selected', role_id);
    }

    // private

    checkClosest(target) {
        const checkpoint = target.parentElement.closest('.permission__checkpoint');
        if (!checkpoint) {
            return;
        }
        const checkboxes = checkpoint.querySelectorAll('.permission__checkpoint__checkbox');
        const [checkpoint_checkbox, ...current_point_targets] = Array.from(checkboxes)
        if (current_point_targets.length == 0) {
            return;
        }

        if (current_point_targets.every(el => el.checked)) {
            checkpoint_checkbox.checked = true;
        } else {
            checkpoint_checkbox.checked = false;
        }

        this.checkClosest(checkpoint);
    }

    unselect() {
        $(this.selectForBulkUpdateTarget).dropdown('clear');
    }

    updateViewBeforeRequest() {
        this.selectTargets.forEach(el=> {
            el.setAttribute('disabled', true)
        });
    }

    updateViewAfterResponse(project_teams) {
        this.projectTeamTargets.forEach(el => {
            const projectTeamId = el.getAttribute("project-team-id");
            const updateResult = project_teams.find(r => r.id == projectTeamId) || {};
            if (updateResult.message) {
                let bg_color;
                const message = updateResult.message;
                if (updateResult.success) {
                    bg_color = 'bg-success';
                } else {
                    bg_color = 'bg-danger';
                }
                el.children[1].outerHTML = `<div class="col-auto h5 d-flex align-items-center mb-0">
                                                <span class="message-area text-white badge ${bg_color}">${message}</span>
                                            </div>`;
                window.setTimeout( () => { el.children[1].outerHTML = "" }, 5000);

                if (!updateResult.success) {
                    $(`#role_id_${updateResult.id}`).dropdown('set selected', updateResult.role_id);
                }
            } else {
                el.children[1].outerHTML = "";
            }
        });
        this.selectTargets.forEach(el=> { el.removeAttribute('disabled') });
    }
}