export default class EventBus {

  static event(event_name) {
    return {
      UNREAD_COUNT_CHANGED: 'unread_count:changed'
    }[event_name]
  }

  // Unread count changed event, payload sample
  // payload: {
  //   data: {
  //     innerHTML: "<span>foo</span>",
  //     // ....
  //   },
  //   bounce: false,
  //   unreadcount: 'UNREAD_COUNTS::strategy:56:126:project'
  //   event_published_at: "2020-05-03T09:04:03.921Z"
  // }

  constructor(default_data={}) {
    this.default_data = default_data
  }

  publish(event_name, payload = {}) {
    if (!EventBus.event(event_name)) {
      throw `Cannot publish to undefined event ${event_name}`
    }

    payload.event_published_at = new Date().toISOString()

    const event = new CustomEvent(event_name, {
      detail: Object.assign(this.default_data, payload),
    })

    if (event_name == 'UNREAD_COUNT_CHANGED') {
      window.dispatchEvent(event);
    } else {
      document.dispatchEvent(event)
    }
  }

  subscribe(event_name, callback) {
    if (!EventBus.event(event_name)) {
      throw `Cannot subscribe to undefined event ${event_name}`
    }
    if (!callback) {
      throw `Cannot subscribe to ${event_name} with an undefined callback`
    }

    if (event_name == 'UNREAD_COUNT_CHANGED') {
      window.addEventListener(event_name, callback)
      return () => window.removeEventListener(event_name, callback)
    } else {
      document.body.addEventListener(event_name, callback)
      return () => document.body.removeEventListener(event_name, callback)
    }
  }
}
