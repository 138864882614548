import { Controller } from "stimulus"

export default class extends Controller {
    static get targets() {
        return [ "notice", "groupIpAddrs", "newIp" ]
    }

    //- 그룹 관리자가 그룹 편집시 보관된 맵 숨기기 여부를 선택하는 경우 관련된 안내 문구를 보여주고 숨기기
    showNotice(event) {
        if (event.currentTarget.checked) {
            this.noticeTarget.classList.remove('d-none')
        } else {
            this.noticeTarget.classList.add('d-none')
        }
    }
    
    //- Ip 추가
    addIp(){
        var row = document.createElement('div');
        row.className = 'row justify-content-between mx-0 my-2';
        row.dataset.target = `group.newIp`;
        
        const ip_addr = ['ip_part1', 'ip_part2', 'ip_part3', 'ip_part4_from', 'ip_part4_to'];
        ip_addr.forEach((el, i) => {
            let newInput = document.createElement('input');
            newInput.setAttribute('type', 'number');
            newInput.setAttribute('name', `group_ip_addrs[newIp_${this.newIpTargets.length}][${el}]`);
            newInput.className = 'form-control col-2';
            newInput.value = 0;
            
            row.appendChild(newInput);
            let newDiv = document.createElement('div');
            if (i < 3) {
                newDiv.innerText = '.';
                newDiv.className = 'align-self-end';
                row.appendChild(newDiv);
            } else if (i == 3) {
                newDiv.innerText = '~';
                newDiv.className = 'align-self-center';
                row.appendChild(newDiv);
            }
        });
        
        var newButton = document.createElement('button');
        newButton.setAttribute('type', 'button');
        newButton.className = 'col-auto btn btn-outline-secondary';
        newButton.dataset.action = "group#removeIp"
        var icon = document.createElement('i');
        icon.className = "fa fa-minus";
        newButton.appendChild(icon);
        
        row.appendChild(newButton);
        this.groupIpAddrsTarget.appendChild(row);
    }

    //- Ip 삭제
    removeIp(event){
        if (event.currentTarget.tagName == "BUTTON"){
            event.currentTarget.parentNode.remove();
        }
        else if (event.currentTarget.tagName == "I"){
            event.currentTarget.parentNode.parentNode.remove();
        }
    }
}