import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [
      "fileField",
      "fileName",
      "uploadFile",
    ]
  }

  connect() {
    //- 처음 모달창이 열리는 경우 파일 input이 비어있으므로 submit 버튼 비활성화
    if (this.hasUploadFileTarget) {
      this.uploadFileTarget.setAttribute('disabled', true)
    }
  }

  //- 버튼 클릭 시 파일 선택 창 열림
  openFileWindow(event) {
    event.preventDefault();
    this.fileFieldTarget.click();
  }

  //- 파일 선택되면 파일 이름 넣어주기
  selectFile() {
    if (this.fileFieldTarget.value) {
      let files = [];
      [...this.fileFieldTarget.files].forEach((el, i, arr) => {
        if (arr.length > 1) {
          files.push(`${(i + 1)}. ${el.name}`);
        } else {
          files.push(`${el.name}`);
        }
      })
      this.fileNameTarget.innerHTML = files.join('<br>');
    }
  }

  //- 선택된 파일이 최소 1개 이상 있는 경우, 파일 input 아래에 파일 명을 표시해주고 submit 버튼 활성화
  activeSubmit() {
    if (this.fileFieldTarget.value) {
      this.uploadFileTarget.removeAttribute('disabled');
      this.selectFile();
    } else {
      this.uploadFileTarget.setAttribute('disabled', true)
    }
  }
}