import { Controller } from "stimulus"

export default class extends Controller {
    static get targets() {
        return ["form", "option", "filter", "detailSearchForm", "detailFilter", "checkbox", "clearConditions", "formElement" ]
    }

    connect() {
        this.setCheckOption();
    }

    //- 필터링 추가하기 시 필터링 뷰 열어주기
    openForm() {
        if (this.formTarget.classList.contains('d-none')) {
            this.formTarget.classList.remove('d-none');
         } else {
            this.formTarget.classList.add('d-none');
         }
         this.minWidthForLabel();
    }

    //- 상세 검색 열고 닫기
    openAndCloseDetailSearch(){
        let openAndCloseButtonForDetailSearch = document.getElementById('openAndCloseButtonForDetailSearch');
        let col;
        if (openAndCloseButtonForDetailSearch) {
            col = openAndCloseButtonForDetailSearch.parentNode;
        }
        if(this.detailSearchFormTarget.classList.contains('show')){
            if (col) {
                col.classList.remove('col-lg-5');
                col.classList.remove('col-md-6');
                col.classList.remove('col-sm-9');
                col.classList.add('col-auto');
            }
            this.detailSearchFormTarget.classList.remove('show');
        } else {
            if (col) {
                col.classList.add('col-lg-5');
                col.classList.add('col-md-6');
                col.classList.add('col-sm-9');
                col.classList.remove('col-auto');
            }
            this.detailSearchFormTarget.classList.add('show');
        }
    }

    //- 필터링 label의 최소길이 설정
    minWidthForLabel(){
        var max = 0;
        var labels = document.getElementsByClassName('filter-label');
        for (let i = 0; i < labels.length; i++) {
            if (labels[i].tagName == "SELECT") {
                max = 140; break;
            }
            max = max<labels[i].clientWidth ? labels[i].clientWidth : max;
        }
        for (let i = 0; i < labels.length; i++) {
           labels[i].style.minWidth = max+1+"px";
        }
    }

    //- 옵션에 따른 체크박스
    changeCheckOption(event) {
        let option = event.currentTarget.getAttribute('option')
        if (option === "message" || option === "pj-message") {
            this.changeFieldByOption(event.target, option)
        } else {
            this.changeSelectByOption(event.target, option)
        }
    }

    //- 옵션에 따라 field 변경
    changeFieldByOption(target, option) {
        let for_unchecked = document.getElementById(`${option}-field-by-option`)
        let for_checked = document.getElementById(`${option}-checked-field-by-option`)
        if (target.checked) {
            for_unchecked.classList.add('d-none')
            for_unchecked.setAttribute('disabled', true);
            for_checked.removeAttribute('disabled');
            for_checked.classList.remove('d-none')
        } else {
            for_checked.classList.add('d-none')
            for_checked.setAttribute('disabled', true);
            for_unchecked.removeAttribute('disabled');
            for_unchecked.classList.remove('d-none')
        }
    }

    //- 옵션에 따라 select 변경
    changeSelectByOption(target, option) {
        let for_unchecked = document.getElementById(`${option}-field-by-option`)
        let for_checked = document.getElementById(`${option}-checked-field-by-option`)
        let disabled_for_unchecked = document.getElementById(`${option}-disabled-field-by-option`)
        let disabled_for_checked = document.getElementById(`${option}-disabled-checked-field-by-option`)
        if (target.checked) {
            for_unchecked.classList.add('d-none')
            disabled_for_unchecked.value = 'default'
            disabled_for_unchecked.setAttribute('data-style', 'bg-white')
            for_checked.classList.remove('d-none')
        } else {
            for_checked.classList.add('d-none')
            disabled_for_checked.value = 'default'
            disabled_for_checked.setAttribute('data-style', 'bg-white')
            for_unchecked.classList.remove('d-none')
        }
    }

    //- connect 시 옵션에 따른 체크박스 세팅
    setCheckOption() {
        const field_options = ["message", "pj-message"]
        const select_options = ["chat", "pj-chat"]

        field_options.forEach((el, i) => {
            if (!!document.getElementById(`${el}-checkbox`)) {
                let for_unchecked = document.getElementById(`${el}-field-by-option`)
                let for_checked = document.getElementById(`${el}-checked-field-by-option`)
                if (document.getElementById(`${el}-checkbox`).checked) {
                    for_unchecked.classList.add('d-none')
                    for_checked.classList.remove('d-none')
                } else {
                    for_checked.classList.add('d-none')
                    for_unchecked.classList.remove('d-none')
                }
            }
        });

        select_options.forEach((el, i) => {
            if (!!document.getElementById(`${el}-checkbox`)) {
                let for_unchecked = document.getElementById(`${el}-field-by-option`)
                let for_checked = document.getElementById(`${el}-checked-field-by-option`)
                let disabled_for_unchecked = document.getElementById(`${el}-disabled-field-by-option`)
                let disabled_for_checked = document.getElementById(`${el}-disabled-checked-field-by-option`)
                if (document.getElementById(`${el}-checkbox`).checked) {
                    for_unchecked.classList.add('d-none')
                    for_checked.classList.remove('d-none')
                } else {
                    for_checked.classList.add('d-none')
                    for_unchecked.classList.remove('d-none')
                }
            }
        });
    }

    clearFilterConditions(event) {
        event.preventDefault();
        this.clearConditionsTarget.setAttribute("checked", true);
        this.filterTargets.forEach(el => el.value = null);
        this.formElementTarget.submit();
    }

    //- 작성자는 한 명 이름으로만 검색되도록
    setNameField(event) {
        if (event.currentTarget.value.length > 0) {
            event.currentTarget.value = ""
        }
    }
}
