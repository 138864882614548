import { Controller } from "stimulus";

export default class extends Controller {
    static get targets() {
        return [ "warning" ]
    }

    getMetaValue(name) {
        const element = document.head.querySelector(`meta[name="${name}"]`)
        return element.getAttribute("content")
    }

    // 팀에 대한 프로젝트의 권한을 변경한 후 일괄 저장할 때 각 프로젝트-팀의 권한을 변경
    editPermission(event) {
        const button = event.target;
        button.setAttribute('disabled', true);
        this.warningTarget.classList.remove('d-none');

        const permissionController = this.application.getControllerForElementAndIdentifier(this.element, "permission");
        permissionController.changeAccess(event);
    }
}