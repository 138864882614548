import { Controller } from "stimulus";
import Calendar from 'tui-calendar';
import moment from 'moment';

export default class extends Controller {
  static get targets() {
    return [
      "form",
      "score",
      "comment",
      "excludeCheck",
      "excludeNotice",
      "loadDiv",
      "assignee",
      "assigneeList",
      "attributeForm",
      "userTasks",
      "newForm",
      "allTaskTags",
      "taskTags",
      "taskList",
      "calendar",
    ]
  }

  connect() {
    //- 새 태스크 생성 모달을 처음 열었을 떄는 assignee 를 선택하지 않은 상태이므로 form 을 숨기기
    if (this.assigneeTargets.length > 0) {
      this.attributeFormTarget.classList.add('d-none');
    }
    if (this.hasNewFormTarget) {
      this.assigneeListTarget.setAttribute("hidden", true);
    }
    if (this.hasCalendarTarget && this.calendarTarget.children.length < 1) {
      this.getCalendar();
    }
  }

  getCalendar() {
    const todayBtn = document.getElementById("todayBtn"),
      prevBtn = document.getElementById("prevBtn"),
      nextBtn = document.getElementById("nextBtn"),
      useWorkweek = document.getElementById("useWorkweek"),
      renderRange = document.getElementById("renderRange"),
      not_started = document.getElementById("not_started"),
      doing = document.getElementById("doing"),
      done = document.getElementById("done"),
      confirmed = document.getElementById("confirmed");
    let schedules = JSON.parse(this.data.get("schedules"));
    schedules.map(schedule => schedule.title += schedule.attendees.join(`<i class="fa fa-arrow-right mx-2"></i>`));

    var cal = new Calendar('#calendar', {
      isReadOnly: true,
      defaultView: 'month',
      month: {
        workweek: false,
        visibleWeeksCount: 5
      },
      taskView: true,
      scheduleView: true,
      useCreationPopup: true,
      useDetailPopup: true,
      usageStatistics: true,
      template: {
        allday: function (schedule) {
          return getTimeTemplate(schedule, true);
        },
        popupDetailUser: function (schedule) {
          return (schedule.attendees || [])[1];
        },
      },
      disableClick: true,
    });

    cal.on({
      clickSchedule: function (e) {
        let html = "",
          progress_bar = document.createElement("div"),
          a = document.createElement("a"),
          schedule = schedules.find(el => el.id == e.schedule.id);
        a.setAttribute('href', schedule.url);
        a.innerHTML = document.getElementsByClassName("tui-full-calendar-schedule-title")[0].innerText;
        if (schedule.id.includes("not_started")) {
          progress_bar.classList.add("progress", "mb-2");
          progress_bar.innerHTML = `<div aria-valuemax="100" aria-valuemin="0" aria-valuenow="100" class="progress-bar border border-dark rounded bg-white" style="width:100%;"></div>`
        } else if (schedule.id.includes("doing")) {
          progress_bar.classList.add("progress", "mb-2");
          progress_bar.innerHTML = `<div aria-valuemax="100" aria-valuemin="0" aria-valuenow="${schedule.completed?.amount}" class="progress-bar progress-bar-striped ${schedule.completed?.fill}" role="progressbar" style="width:${schedule.completed?.amount}%;">${schedule.label}</div>`
        } else if (schedule.id.includes("done")) {
          progress_bar.classList.add("progress", "mb-2");
          progress_bar.innerHTML = `<div aria-valuemax="100" aria-valuemin="0" aria-valuenow="100" class="progress-bar border border-danger text-danger text-center rounded bg-white" role="progressbar" style="width:100%;">${schedule.label}</div>`
        } else {
          progress_bar.innerHTML = ""
        }
        html += progress_bar.outerHTML
        html += a.outerHTML
        document.getElementsByClassName("tui-full-calendar-schedule-title")[0].innerHTML = html;
      },
    });
    todayBtn.addEventListener("click", e => {
      cal.today();
      setRenderRangeText();
    });

    prevBtn.addEventListener("click", e => {
      cal.prev();
      setRenderRangeText();
    });

    nextBtn.addEventListener("click", e => {
      cal.next();
      setRenderRangeText();
    });

    useWorkweek.addEventListener("change", e => {
      cal.setOptions({
        month: {
          workweek: e.currentTarget.checked
        }
      });
    });

    handleCheckChildElement(not_started)
    not_started.addEventListener("change", e => handleCheckChildElement(e.target));

    handleCheckChildElement(doing)
    doing.addEventListener("change", e => handleCheckChildElement(e.target));

    handleCheckChildElement(done)
    done.addEventListener("change", e => handleCheckChildElement(e.target));

    handleCheckChildElement(confirmed)
    confirmed.addEventListener("change", e => handleCheckChildElement(e.target));

    function getTimeTemplate(schedule, isAllDay) {
      const html = [];
      const start = moment(schedule.start.toUTCString());
      const {
        title,
        isPrivate,
        isReadOnly,
        recurrenceRule,
        attendees,
        location
      } = schedule;

      if (!isAllDay) {
        html.push(`<strong>${start.format("HH:mm")}</strong>`);
      }
      if (isPrivate) {
        html.push('<span class="calendar-font-icon ic-lock-b"></span>');
        html.push(" Private");
      } else {
        if (isReadOnly) {
          html.push('<span class="calendar-font-icon ic-readonly-b"></span>');
        } else if (recurrenceRule) {
          html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
        } else if (attendees.length) {
          html.push('<span class="calendar-font-icon ic-user-b"></span>');
        } else if (location) {
          html.push('<span class="calendar-font-icon ic-location-b"></span>');
        }
        html.push(` ${title}`);
      }

      return html.join("");
    }

    function setSchedules(schedules) {
      cal.clear();
      cal.createSchedules(schedules);
      cal.render();
      setRenderRangeText();
    }

    function setRenderRangeText() {
      let html = []
      html.push(moment(cal.getDateRangeStart().getTime()).format("YYYY.MM.DD"));
      html.push(" ~ ");
      html.push(moment(cal.getDateRangeEnd().getTime()).format(" MM.DD"));
      renderRange.innerHTML = html.join("");
    }

    // 선택된 태스크 목록만 보여주기
    function handleCheckChildElement(target) {
      const cloneCheckedCalendars = [...schedules];
      cloneCheckedCalendars.forEach(el => {
        if (el.id.includes(target.id)) el.isChecked = target.checked;
      });

      // 제외되어야 할 태스크 목록
      const filterCalendars = cloneCheckedCalendars.filter(el => el.isChecked === false).map(item => item.id);
      // 표시되어야 할 태스크 목록
      const cloneSchedules = schedules.filter(el => filterCalendars.indexOf(el.calendarId) === -1);
      setSchedules(cloneSchedules)
    };
  };

  //- 유저 프로필 화면에서 태스크 만들기
  loadNewForm() {
    var user_id = this.newFormTarget.getAttribute("user-id");
    document.getElementById("assignees_" + user_id).setAttribute("checked", true);
    this.attributeFormTarget.classList.remove("d-none")
  }

  //- 태스크 확인 시 ‘평가에서 제외하기’를 선택하면 점수 입력창을 비활성화하고 알림 문구 보여주기
  //- 태스크 확인 시 ‘평가에서 제외하기’를 선택하지 않으면 점수 입력창을 활성화하고 알림 문구 숨기기
  toggleExclude() {
    if (this.excludeCheckTarget.checked) {
      this.scoreTarget.setAttribute('disabled', true)
      this.excludeNoticeTarget.classList.remove('d-none')
    } else {
      this.scoreTarget.removeAttribute('disabled')
      this.excludeNoticeTarget.classList.add('d-none')
    }
  }

  //- 태스크 확인 시 ‘평가에서 제외하기’를 선택하면 코멘트만 입력되면 확인 후 submit
  //- 태스크 확인 시 ‘평가에서 제외하기’를 선택하지 않으면 점수와 코멘트 모두 입력되면 확인 후 submit
  confirm(event) {
    if (this.excludeCheckTarget.checked) {
      if (this.commentTarget.value.length == 0) {
        alert(this.data.get("commentMessage"))
        event.preventDefault();
      } else {
        if (!confirm(`${this.data.get("confirmTitle")}\n\n` +
            `${this.data.get("confirmComment")} : ${this.commentTarget.value}\n\n` +
            this.data.get("confirmMessage"))
        ) {
          event.preventDefault();
        }
      }
    } else {
      if (this.scoreTarget.value.length == 0) {
        alert(this.data.get("scoreMessage"))
        event.preventDefault();
      } else if (this.scoreTarget.value > parseInt(this.data.get("maxScore"))) {
        alert(this.data.get("maxScoreMessage"))
        event.preventDefault();
      } else if (this.commentTarget.value.length == 0) {
        alert(this.data.get("commentMessage"))
        event.preventDefault();
      } else {
        if (!confirm(`${this.data.get("confirmTitle")}\n\n` +
            `${this.data.get("confirmScore")} : ${this.scoreTarget.value}\n` +
            `${this.data.get("confirmComment")} : ${this.commentTarget.value}\n\n` +
            this.data.get("confirmMessage"))
        ) {
          event.preventDefault();
        }
      }
    }
  }

  //- 태스크 상태 변화 : 하는 중, 다했음, 좀 부족, 확인 시
  changeStatus(event) {
    let currentTarget = event.currentTarget || event.target;
    currentTarget.setAttribute('disabled', true);
    const [data, status, xhr] = event.detail;
    let { message } = JSON.parse(xhr.response);
    if (message) {
      alert(message);
      location.reload();
    }
  }

  //- 태스크 목록에서 더보기로 30개씩 추가 로드
  load(event) {
    let currentTarget = event.currentTarget || event.target;
    let url = currentTarget.getAttribute("url");
    fetch(url)
      .then(response => response.text())
      .then(body => {
        this.loadDivTarget.outerHTML = body;
        currentTarget.classList.add('d-none')
      });
  }

  //- 새 태스크 생성 시 assignee 를 선택해야 form 보여주고, 아니면 숨기기
  toggleUserList() {
    if (this.assigneeTargets.filter(checkbox => checkbox.checked).length > 0) {
      this.attributeFormTarget.classList.remove('d-none')
    } else {
      this.attributeFormTarget.classList.add('d-none')
    }
  }

  //- 맵 태스크 목록에서 태스크 받은 사람으로 묶어서 보기 시 받은 사람별로 태스크 로드
  openGroupedTasks() {
    let url = this.data.get("dataUrl");
    if (this.userTasksTarget.classList.contains('opened-team')) {
      this.userTasksTarget.classList.add('d-none');
      this.userTasksTarget.classList.remove('opened-team');
    } else {
      if (document.getElementsByClassName("opened-team").length > 0) {
        document.getElementsByClassName("opened-team")[0].classList.add('d-none');
        document.getElementsByClassName("opened-team")[0].classList.remove('opened-team');
      }

      fetch(url)
        .then(response => response.text())
        .then(body => {
          this.userTasksTarget.innerHTML = body;
          window.setTooltip();
        });
      this.userTasksTarget.classList.remove('d-none');
      this.userTasksTarget.classList.add('opened-team');
    }
  }

  //- 모든 체크박스의 체크를 하거나 제외하거나
  checkAll(event) {
    let currentTarget = event.currentTarget || event.target;
    let url = this.data.get("contentsUrl");
    if (currentTarget.checked) {
      this.taskTagsTargets.forEach((el, i) => {
        el.checked = true
      });
    } else {
      this.taskTagsTargets.forEach((el, i) => {
        el.checked = false
      });
    }
    this.loadTaskList(url)
  }

  //- 하나의 체크박스만 선택 / 해제
  checkOne(event) {
    let currentTarget = event.currentTarget || event.target;
    let url = this.data.get("contentsUrl");
    if (currentTarget.checked) {
      if (!this.allTaskTagsTarget.checked && (this.taskTagsTargets.filter(checkbox => checkbox.checked).length == this.taskTagsTargets.length)) {
        this.allTaskTagsTarget.checked = true
      }
    } else {
      if (this.allTaskTagsTarget.checked) {
        this.allTaskTagsTarget.checked = false
      }
    }
    if (this.taskListTarget.classList.contains('open-task-list')) {
      this.loadTaskList(url)
    }
  }

  //- 통제활동 대시보드에서 태스크 리스트를 로드하기
  showTaskList() {
    let url = this.data.get("contentsUrl");
    if (this.taskListTarget.classList.contains('open-task-list')) {
      this.taskListTarget.classList.remove('open-task-list');
      this.taskListTarget.classList.add('d-none');
    } else {
      this.taskListTarget.classList.add('open-task-list');
      this.taskListTarget.classList.remove('d-none');
      this.loadTaskList(url)
    }
  }

  loadTaskList(url) {
    this.taskListTarget.innerHTML = "<div class='text-center'><i class='fa fa-spinner fa-pulse fa-4x mt-4 mb-4'></i></div>";

    let checked_one = this.taskTagsTargets.filter(checkbox => checkbox.checked);
    let parseUrl = new URL(`${url}`);
    if (checked_one.length > 0) {
      checked_one.forEach((el, i) => {
        parseUrl.searchParams.append("task_tags[]", el.value);
      });
    }
    fetch(parseUrl)
      .then(response => response.text())
      .then(body => {
        //- 노트 리스트 넣어주고
        this.taskListTarget.innerHTML = body;
        //- 목록에서 즐겨찾기 tooltip 동작할 수 있도록 추가
        $("[data-toggle='tooltip']").tooltip();
      });
  }
}