import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [
      "description",
    ]
  }

  connect() {
    this.initializeDetailTab();
  }

  initializeDetailTab() {
    if (!this.hasCancelButtonTarget)
      return;
    const cancelButton = this.cancelButtonTarget;
    const formData = new FormData(cancelButton.form);
    const description = formData.get('strategy[description]');

    cancelButton.addEventListener('click', e => {
      this.descriptionTarget.value = description;
      this.descriptionTarget.setAttribute("readonly", true);
    });
  }

  edit(e) {
    this.descriptionTarget.removeAttribute("readonly");
  }

  //- 프로젝트 편집
  update(event) {
    event.preventDefault();
    const controller = this;
    const currentTarget = event.currentTarget || event.target;
    const url = currentTarget.getAttribute("action");
    const load_url = currentTarget.getAttribute("url");

    const formData = new FormData(event.target);

    Rails.ajax({
      type: "PUT",
      url: url,
      headers: {
        "X-CSRF-Token": window.getMetaValue("csrf-token"),
        "Content-Type": "application/json"
      },
      data: formData,
      success: function (data) {
        if (load_url && data.status === "updated") {
          // 본부장이 KPI 목표 및 실적 수정
          controller.fetch_url(load_url, controller.element);
        }
      }
    });
  }

  fetch_url(url, target) {
    fetch(url)
      .then(response => response.text())
      .then(body => {
        target.outerHTML = body;
      });
  }

}