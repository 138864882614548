import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["modal", "dimmer"];

    //- 모달창 열기
    open() {
        document.body.classList.add("modal-open");
        this.element.setAttribute("style", "display: block;");
        this.element.classList.add("show");
        this.element.style.background = "rgba(0, 0, 0, 0.6)";
    }

    //- 모달창 닫기
    close() {
        document.body.classList.remove("modal-open");
        this.element.removeAttribute("style");
        this.element.classList.remove("show");
        this.element.innerHTML = '';
    }

    fetch(event) {
        const currentTarget = event.currentTarget || event.target;
        if (currentTarget.tagName == 'A') {
            return;
        }

        const url = currentTarget.getAttribute('url');
        const target = () => document.querySelector(`#modal-dimmer > .modal[data-url="${url}"]`);

        if (currentTarget.type === 'text') {
            currentTarget.parentNode.classList.add('icon', 'input', 'ui', 'loading');
            [...currentTarget.parentNode.children].find(el => el.tagName === 'I')?.classList?.remove('d-none');
        }
        else {
            currentTarget.classList.add('disabled', 'loading');
        }

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error();
                  }
          
                  return response.text();
            })
            .then(body => {
                const dimmer = document.querySelector('#modal-dimmer');
                if (target()) 
                    target().outerHTML = body;
                else
                    dimmer.innerHTML = body;
                this.show(target());
            })
            .catch(error => {

            })
            .finally(() => {
                if (currentTarget.type === 'text') {
                    currentTarget.parentNode.classList.remove('icon', 'input', 'ui', 'loading');
                    [...currentTarget.parentNode.children].find(el => el.tagName === 'I')?.classList?.add('d-none');
                }
                else {
                    currentTarget.classList.remove('disabled', 'loading');
                }
            });
    }

    show(target) {
        if (!target) {
            return;
        }
        document.body.style.overflowY = 'hidden';
        const dimmer = document.querySelector('#modal-dimmer');
        // dimmer.classList.remove('hidden', 'fade', 'out');
        // dimmer.classList.add('active', 'fade', 'in');
        dimmer.classList.remove('hidden');
        dimmer.classList.add('active');
        target.classList.remove('hidden');
        target.classList.add('active');
    }

    hide() {
        document.body.style.overflowY = 'unset';
        const dimmer = this.modalTarget.closest('.ui.dimmer.modals');
        // dimmer.classList.remove('fade', 'in');
        // dimmer.classList.add('fade', 'out');
        dimmer.classList.remove('active');
        dimmer.classList.add('hidden');
        this.modalTarget.classList.remove('active');
        this.modalTarget.classList.add('hidden');
    }

    hideAll(event) {
        const dimmer = document.querySelector('#modal-dimmer');
        if (event.target != dimmer) {
            return;
        }

        const isReloadOnModalClose = dimmer.classList.contains('reload');
        if (isReloadOnModalClose) {
            location.reload();
        }

        document.body.style.overflowY = 'unset';
        // event.target.classList.remove('fade', 'in');
        // event.target.classList.add('fade', 'out');
        event.target.classList.remove('active');
        event.target.classList.add('hidden');
        const modals = document.body.querySelectorAll('.ui.modal');
        modals.forEach(t => {
            t.classList.remove('active');
            t.classList.add('hidden');
        })
    }
}