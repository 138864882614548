import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [
      "score",
      "maxScore",
      "totalScore",
      "totalMaxScore",
      "comment",
      "form",
      "team",
      "nextTeams",
      "nextTeam",
      "lastTeam",
      "sendTeam"
    ]
  }

  connect() {
    const controller = this;
    controller.element.addEventListener("submit", (e) => {
      let nextTeams = [];
      if (controller.hasNextTeamsTarget)
        nextTeams = controller.nextTeamsTarget.childNodes;
      nextTeams = [...nextTeams].filter((el) => el.querySelector("select").value);
      if (!e.target.classList.contains('edit_receive_manager') && nextTeams.length <= 0) {
        if (!confirm(controller.data.get("routeConfirmMessage"))) {
          e.preventDefault();
        }
      }
    });
  }

  totalScore(event) {
    var sum = 0;
    if (event.currentTarget.id.split('_')[1] == "max") {
      this.maxScoreTargets.forEach((el, i) => {
        sum += parseInt(el.value);
      });
      this.totalMaxScoreTarget.value = sum;
    } else {
      this.scoreTargets.forEach((el, i) => {
        sum += parseInt(el.value);
      });
      this.totalScoreTarget.value = sum;
    }
  }

  //- 이슈 확인 시 점수와 코멘트가 모두 입력되면 submit
  issueClose(event) {
    event.preventDefault();
    if (this.totalScoreTarget.value.length == 0 || this.totalMaxScoreTarget.value.length == 0) {
      alert(this.data.get("scoreMessage"));
    } else if (this.totalMaxScoreTarget.value < parseInt(this.data.get("maxScore")) || this.totalMaxScoreTarget.value > parseInt(this.data.get("maxScore"))) {
      alert(this.data.get("maxScoreMessage"));
    } else if (parseInt(this.totalScoreTarget.value) > parseInt(this.totalMaxScoreTarget.value)) {
      alert(this.data.get("exceedsMaxMessage"))
    } else if (this.commentTarget.value.length == 0) {
      alert(this.data.get("commentMessage"))
    } else {
      if (confirm(this.data.get("confirmTitle") + "\n\n" +
          this.data.get("confirmScore") + " : " + this.totalScoreTarget.value + "\n" +
          this.data.get("confirmComment") + " : " + this.commentTarget.value + "\n\n" +
          this.data.get("confirm"))) {
        this.formTarget.submit()
      }
    }
  }

  //- 이슈 경로 설정에 팀 추가
  addSelect() {
    var newDiv = document.createElement("div");
    newDiv.className = "row justify-content-between my-1 mx-0"
    newDiv.innerHTML = this.nextTeamTarget.innerHTML;
    if (newDiv.hasChildNodes()) {
      var children = newDiv.childNodes;
      for (var i = 0; i < children.length; i++) {
        if (children[i].tagName == "SELECT") {
          children[i].setAttribute("required", true);
          children[i].setAttribute("data-target", "issue.team");
          this.setDisabledTeam(children[i]);
        }
      }
    }
    this.nextTeamsTarget.appendChild(newDiv);
    this.setLastTeam();
  }

  //- 이슈 경로 설정에서 팀 제거
  removeSelect(event) {
    this.setNextTeams(event);
    let currentTarget = event.currentTarget || event.target;
    let nextTeams = currentTarget.parentNode.parentNode;
    let row = currentTarget.parentNode;
    if (currentTarget.tagName == "I") {
      nextTeams = nextTeams.parentNode;
      row = row.parentNode;
    }
    nextTeams.removeChild(row);
    let hidden = row.querySelector(`input[type=hidden][name^="issue[team_orders]`);
    let select = row.querySelector("select");
    if (hidden) {
      hidden.value = "";
      nextTeams.appendChild(hidden);
    } else if (select?.value) {
      hidden = document.createElement("input");
      hidden.type = "hidden";
      hidden.name = select.name;
      hidden.id = select.id;
      nextTeams.appendChild(hidden);
    }
    this.setNextTeams(event);
  }

  //- select 에 name 과 id 세팅
  setNextTeams(event) {
    const controller = this;
    let currentTarget = event.currentTarget || event.target;
    const form = currentTarget.form;
    if (form && form.classList.contains('edit_receive_manager')) {
      const selected_teams = this.teamTargets.filter((el, i) => el.type == "checkbox" ? el.checked : el.value)
      const submit_button = form.querySelector('input[type=submit]');
      submit_button.disabled = selected_teams <= 0;
    } else {
      this.teamTargets.filter((el, i) => {
        let hidden = document.querySelector(`input[type=hidden][name^="issue[team_orders][${i}]"]`);
        let select = document.querySelector(`select[name^="issue[team_orders][${i}]"]`);

        if (el.name?.includes('team_orders')) {
          console.log("el: ", el);
          if (hidden) {
            let hiddenInput = document.createElement("input");
            hiddenInput.type = "hidden";
            hiddenInput.name = el.name;
            hiddenInput.id = el.id;
            el.name = hidden.name;
            el.id = hidden.id;
            hidden.remove();
            controller.nextTeamsTarget.appendChild(hiddenInput);
          }
        } else {
          if (hidden) {
            el.name = hidden.name;
            el.id = hidden.id;
            hidden.remove();
          } else if (select?.value) {
            el.name = select.name;
            el.id = select.id;
            select.name = "";
            select.id = "";
          } else if (el.value) {
            el.name = `issue[team_orders][${i}][${el.value}]`;
            el.id = `issue_team_orders_${i}_${el.value}`;
          }
        }
      });
      let lastIndex = this.teamTargets.length;
      if (lastIndex > 1) {
        let select = this.lastTeamTarget.childNodes[0];
        let hidden = document.querySelector(`input[type=hidden][name^="issue[team_orders][${lastIndex}]"]`);
        if (hidden) {
          let hiddenInput = document.createElement("input");
          hiddenInput.type = "hidden";
          hiddenInput.name = select.name;
          hiddenInput.id = select.id;
          select.name = hidden.name;
          select.id = hidden.id;
          hidden.remove();
          controller.nextTeamsTarget.appendChild(hiddenInput);
        } else if (select) {
          select.name = select.name || `issue[team_orders][${lastIndex}][${select.value}]`;
          select.id = select.id || `issue_team_orders_${lastIndex}_${select.value}`;
        }
      }
      this.setLastTeam();
    }
  }

  //- 이슈 경로 설정에 팀 추가/제거 및 이슈 만들 팀 변경 시 마지막 팀을 자동 변경
  setLastTeam() {
    if (!this.hasLastTeamTarget)
      return;
    let select = this.lastTeamTarget.childNodes[0];
    select.value = this.sendTeamTarget.value;
    let lastIndex = this.teamTargets.length - 1;
    if (lastIndex > 0 && this.teamTargets[lastIndex].value !== select.value) {
      this.lastTeamTarget.classList.remove('d-none');
    } else {
      select.value = "";
      this.lastTeamTarget.classList.add('d-none');
    }
  }

  //- 이슈 경로 설정에서 팀 선택했던 select 변경 시 다음 select 에서 disabled 될 팀 변경
  changeNextDisabledTeam(event) {
    this.teamTargets.forEach((el, i) => {
      if (event.target === el) {
        let select = this.teamTargets[i + 1]
        this.setDisabledOption(select, event.currentTarget.value)
      }
    })
  }

  //- 이슈 경로 설정에서 팀 추가 시 해당 select 에서 disabled 될 팀 설정
  setDisabledTeam(select) {
    let index = this.teamTargets.length - 1
    this.setDisabledOption(select, this.teamTargets[index].value)
  }

  //- select 에서 disabled 될 option 설정
  setDisabledOption(select, value) {
    if (!select)
      return;
    for (var i = 0; i < select.options.length; i++) {
      if (select.options[i].value === value) {
        select.options[i].setAttribute('disabled', 'disabled')
      } else {
        select.options[i].removeAttribute('disabled')
      }
    }
  }
}