import { Controller } from "stimulus";

export default class extends Controller {
  static get targets() {
    return [];
  }

  request(event) {
    const url = this.data.get('url');
    const requestInit = {
      method: 'put',
      headers: {
        "X-CSRF-Token": window.getMetaValue("csrf-token")
      }
    };

    const requestButton = document.querySelector('.map__archive__warning__button');
    requestButton.classList.add('loading', 'disabled');

    fetch(url, requestInit)
      .then(response => {
        if (!response.ok) {
          throw new Error();
        }

        return response.json();
      })
      .then(body => {
        console.debug(body);

        const warningElmt = document.querySelector('.map__archive__warning');
        const doneElmt = document.querySelector('.map__archive__done');
        warningElmt.classList.add('d-none');
        doneElmt.classList.remove('d-none');

        const dimmer = document.querySelector('#modal-dimmer');
        dimmer.classList.add('reload');
      })
      .catch(error => {

      })
      .finally(() => {
        requestButton.classList.remove('loading', 'disabled');
      })
  }
}