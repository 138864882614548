import { Controller } from "stimulus"
import consumer from '../channels/consumer'

export default class extends Controller {
    static targets = ["loadedHtml"]

    connect() {
        let controller = this;
        let chat_id = this.data.get("chat-id")
        this.channel = consumer.subscriptions.create('ChatChannel', {
            received(data) {
                if (chat_id == data["chat_id"] && data["peek_last_message"] != "") {
                    controller.loadedHtmlTarget.innerHTML = data["peek_last_message"]
                }
            }
        });
    }

    disconnect() {
        if (!!this.channel) {
            this.channel.unsubscribe();
        }
    }
}
