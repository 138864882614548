import { Controller } from "stimulus"

export default class extends Controller {
    static get targets() {
        return [ "unread", "notice", "noticeModal" ]
    }

    //- 등록된 공지사항 내용을 보여주는 모달창 열고 안읽음 badge 제거
    showNotice() {
        let url = this.data.get("url");
        if (this.hasUnreadTarget) {
            this.unreadTarget.classList.add('d-none')
        }
        fetch(url)
            .then(response => response.text())
            .then(body => {
                this.noticeTarget.innerHTML = body;
                if (this.hasNoticeModalTarget) {
                    let modalController = this.application.getControllerForElementAndIdentifier(
                        this.noticeTarget,
                        "modal"
                    );
                    modalController.open();
                }
            });
    }
}