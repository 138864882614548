import { Controller } from "stimulus";

export default class extends Controller {
  static get targets() {
    return ["title", "content", "transition", "checkbox"]
  }

  toggle(event) {
    if (this.titleTarget.classList.contains('active')) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    this.titleTarget.classList.add('active');
    this.contentTarget.classList.add('active');
    this.transitionTarget.classList = "visible d-block";
  }

  close() {
    this.titleTarget.classList.remove('active');
    this.contentTarget.classList.remove('active');
    this.transitionTarget.classList = "transition hidden";
  }
}