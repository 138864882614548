import { Controller } from "stimulus"
import consumer from '../channels/consumer'

export default class extends Controller {
  static targets = ["loadedHtml"]
  connect() {
    let load_on_demand = (this.data.get("load-on-demand") == 'true')
    if (load_on_demand != true) {
      this.load()
    }
  }

  load(force=false,now=true) {
    let url = this.data.get("url")
    if (window.html_cache == undefined) {
      window.html_cache = Array()
    }
    if (window.fetch_timeout == undefined) {
      window.fetch_timeout = Array()
    }
    let force_value = force
    if (force || window.html_cache[url] == undefined) {
      if (window.fetch_timeout[url] != undefined) {
        window.clearTimeout(window.fetch_timeout[url])
      }
      if (now) {
        this.fetch_later(url,force_value)
      } else {
        var timeoutID = window.setTimeout( () => {this.fetch_later(url,force_value)}, 60000)
        window.fetch_timeout[url] = timeoutID
      }
    } else {
      this.loadedHtmlTarget.innerHTML = window.html_cache[url]
    }
  }

  fetch_later(url,force) {
    console.log("fetching :"+url)
    let force_value = force
    fetch(url)
      .then(response => {
        if (response.redirected) {
          window.location.href = response.url;
        } else if (response.ok) {
          return response.text()
        } else {
          this.load(true)
          throw "404"
        }
      })
      .then(body => {
        this.loadedHtmlTarget.innerHTML = body
        if (force_value) {
          if (body != window.html_cache[url]) {
            if (!!this.loadedHtmlTarget.firstElementChild) {
              this.loadedHtmlTarget.firstElementChild.classList.add('animated','bounce')
            }
          }
        }
        window.html_cache[url] = body
      })
      .catch(err => {
        console.log("error fetching: " + err)
      })
  }
}
