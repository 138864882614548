import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [
      "title",
      "content",
      "dueDate",
      "project",
      "team",
      "plannable",
      "form",
      "submitButton",
      "contents",
      "confidenceScore",
      "hashtagField",
      "addHashtagField",
      "hashtagBadge",
      "template",
      "templateModal",
      "secretTitle",
      "blindTitle",
      "blindButton",
      "favoriteIcon",
      "unreadBadge",
      "openContentsIcon",
      "allActivityTags",
      "activityTags",
      "printList",
      "printIcon",
      "areaNotToPrint",
      "noteList"
    ]
  }

  connect() {
    let secret_url = this.data.get("secretUrl");
    let blind_url = this.data.get("blindUrl");
    let blind_button_url = this.data.get("blindButtonUrl");
    let favorite_icon_url = this.data.get("favoriteIconUrl");
    let url = this.data.get("contentsUrl")

    if (this.hasProjectTarget && this.hasTeamTarget && this.hasPlannableTarget)
      this.beforeGetSelectableResources();

    //- 비밀노트인 경우 노트 제목 변경
    if (!!secret_url) {
      fetch(secret_url)
        .then(response => response.text())
        .then(body => {
          if (body.length > 0 && this.hasSecretTitleTarget) {
            this.secretTitleTarget.innerHTML = body;
          }
        });
    }
    //- 블라인드 처리된 노트인 경우에 노트 제목 변경
    if (!!blind_url) {
      fetch(blind_url)
        .then(response => response.text())
        .then(body => {
          if (body.length > 0 && this.hasBlindTitleTarget) {
            this.blindTitleTarget.innerHTML = body;
          }
        });
    }
    //- 유저가 본부장인 경우에 블라인드 처리 버튼 추가
    if (!!blind_button_url) {
      fetch(blind_button_url)
        .then(response => response.text())
        .then(body => {
          if (body.length > 0 && this.hasBlindButtonTarget) {
            this.blindButtonTarget.innerHTML = body;
          }
        });
    }
    //- 유저가 즐겨찾기한 노트에 즐겨찾기 아이콘 추가
    if (!!favorite_icon_url) {
      fetch(favorite_icon_url)
        .then(response => response.text())
        .then(body => {
          if (body.length > 0 && this.hasFavoriteIconTarget) {
            this.favoriteIconTarget.classList.remove(body);
          }
        });
    }

    //- 본문이 아닌 해시태그 필드에서 작성된 해시태그가 있는 경우 아래에 badge로 모두 추가
    if (this.hasHashtagBadgeTarget) {
      let tags = this.hashtagFieldTarget.value.replace(/,/g, "");
      this.hashtagFieldTarget.value = tags.trim();
      tags.split(" ").forEach((el, i) => {
        if (el.length > 0) {
          var new_tag_badge = document.createElement('span');
          new_tag_badge.innerHTML = "<span class='badge badge-pill badge-info mr-2'>" + el + "<i class='fa fa-times ml-1' data-action='click->activity#removeHashtag'></i></span>";
          this.hashtagBadgeTarget.appendChild(new_tag_badge);
        }
      });
    }

    //- 계획 내에서 노트 리스트 보기 시 페이지 변경 때마다 다시 노트 리스트를 로드
    if (this.hasNoteListTarget) {
      if (this.noteListTarget.classList.contains('open-note-list') || location.search.split('page=')[1] != undefined) {
        url += url.split("page=").length == 1 ? "?" : "&";
        url += `page=${location.search.split('page=')[1]}`;
        this.noteListTarget.classList.add('open-note-list');
        this.noteListTarget.classList.remove('d-none');
        this.loadNoteList(url);
      }
    }
  }

  //- 노트 프린트
  print(event) {
    let activity_id = event.currentTarget.getAttribute("activity-id");
    //- 노트 리스트에서 프린트 하는 경우
    if (!!document.getElementById(activity_id + '-activity-list-content')) {
      document.getElementById(activity_id + '-activity-list-content').classList.add("d-print-block");
      window.onafterprint = function () {
        document.getElementById(activity_id + '-activity-list-content').classList.remove("d-print-block");
      };
    }

    window.print();
  }

  //- 노트 작성 중에 엔터키 입력 불가능하도록 : 노트 작성창에 있는 기타 버튼이 엔터키로 클릭되는 것을 방지
  preventEnterKey(event) {
    if (event.keyCode == 13) {
      event.preventDefault();
      return false;
    }
  }

  //- 노트에 수행일자, 제목, 내용이 모두 입력된 경우 노트 submit 후 임시 저장된 노트 삭제
  activitySubmit(event) {
    event.preventDefault();
    if (this.hasDueDateTarget && this.dueDateTarget.value.length == 0) {
      alert(this.data.get("dateMessage"));
      this.dueDateTarget.focus();
    } else if (this.hasProjectTarget && this.projectTarget.value.length == 0) {
      alert(this.data.get("projectMessage"));
      this.projectTarget.focus();
    } else if (this.hasTeamTarget && this.teamTarget.value.length == 0) {
      alert(this.data.get("teamMessage"));
      this.teamTarget.focus();
    } else if (this.hasPlannableTarget && this.plannableTarget.value.length == 0) {
      alert(this.data.get("plannableMessage"));
      this.plannableTarget.focus();
    } else if (this.hasTitleTarget && this.titleTarget.value.length == 0) {
      alert(this.data.get("titleMessage"));
      this.titleTarget.focus();
    } else if (this.hasContentTarget && this.contentTarget.value.length == 0) {
      alert(this.data.get("contentMessage"));
      this.contentTarget.focus();
    } else if (this.hasConfidenceScoreTarget && (this.confidenceScoreTarget.value <= 0 || this.confidenceScoreTarget.value > 10)) {
      alert(this.data.get("confidenceScore"));
    } else {
      if (!confirm(this.data.get("confirmCreateNewNote"))) return;
      this.submitButtonTarget.setAttribute('disabled', true);
      this.hashtagFieldTarget.value = this.hashtagFieldTarget.value.trim().replace(/\s+/g, ", ");
      this.formTarget.submit()
      localStorage.removeItem('editor_' + this.data.get("userId"))
    }
  }

  //- 노트에 대화방이 생성되어 별도로 해시태그만 수정하는 경우
  activityHashtagSubmit(event) {
    event.preventDefault();
    let new_tag = this.addHashtagFieldTarget.value.split('#')[1].replace(/\s/g, "");
    if (new_tag.length > 0) {
      if (this.hashtagFieldTarget.value == "") {
        this.hashtagFieldTarget.value = new_tag;
      } else {
        this.hashtagFieldTarget.value = this.hashtagFieldTarget.value + " " + new_tag;
      }
      this.hashtagFieldTarget.value = this.hashtagFieldTarget.value.trim().replace(/\s+/g, ", ");
      this.formTarget.submit()
    } else {
      this.hashtagFieldTarget.value = this.hashtagFieldTarget.value.trim().replace(/\s+/g, ", ");
      this.formTarget.submit()
    }
  }

  //- 노트 목록에서 노트 본문 열고 노트 안읽음 badge 있으면 제거
  openContents(event) {
    this.contentsTarget.classList.add('text-center');
    this.contentsTarget.innerHTML = "<i class='fa fa-spinner fa-pulse fa-2x mt-4 mb-4'></i>";
    let url = this.data.get("contentsUrl");
    fetch(url)
      .then(response => response.text())
      .then(body => {
        this.contentsTarget.classList.remove('text-center');
        this.contentsTarget.innerHTML = body
      });
    this.contentsTarget.classList.toggle('d-none');
    if (event.currentTarget.classList.contains("fa-angle-down")) {
      this.openContentsIconTargets.forEach((el, i) => {
        el.classList.replace("fa-angle-down", "fa-angle-up");
        el.classList.add('animated', 'rotateIn');
      });
    } else {
      this.openContentsIconTargets.forEach((el, i) => {
        el.classList.replace("fa-angle-up", "fa-angle-down");
        el.classList.add('animated', 'rotateIn');
      });
    }
    this.openContentsIconTargets.forEach((el, i) => {
      el.addEventListener('animationend', () => {
        el.classList.remove('animated', 'rotateIn')
      })
    });
  }

  // 프로젝트/팀 및 플랜/이슈 선택 이전의 값 저장해두기
  beforeGetSelectableResources() {
    $('.custom-select').selectpicker();
    const team_button = document.querySelector('button[data-id^="activity_team"]');
    const project_button = document.querySelector('button[data-id^="activity_project"]');
    const plannable_button = document.querySelector('button[data-id^="activity_plan"], button[data-id^="activity_issue"]');
    let previous_project, previous_team, previous_plannable;
    project_button?.addEventListener('click', (event) => previous_project = this.projectTarget.value);
    this.projectTarget?.addEventListener('focus', (event) => previous_project = event.currentTarget.value);
    this.projectTarget?.addEventListener('change', (event) => this.getSelectablePlannables(event, previous_project));

    team_button?.addEventListener('click', (event) => previous_team = this.teamTarget.value);
    this.teamTarget?.addEventListener('focus', (event) => previous_team = event.currentTarget.value);
    this.teamTarget?.addEventListener('change', (event) => this.getSelectablePlannables(event, previous_team));

    plannable_button?.addEventListener('click', (event) => previous_plannable = this.plannableTarget.value);
    this.plannableTarget?.addEventListener('focus', (event) => previous_plannable = event.currentTarget.value);
    this.plannableTarget?.addEventListener('change', (event) => this.getSelectableProjectsAndTeams(event, previous_plannable));
  }

  //- 노트 작성 시 플랜/이슈 변경하면 프로젝트/팀 기본 설정
  async getSelectableProjectsAndTeams(event, prev) {
    const team = this.teamTarget;
    const project = this.projectTarget;
    const plannable = this.plannableTarget;
    const url = this.data.get("selectableResourcesUrl");
    let parsedUrl = new URL(`${location.origin}${url}`);
    let activity_id = document.getElementById("activity_id")?.value;
    parsedUrl.searchParams.set('activity_id', activity_id);
    if (plannable?.value === '') {
      if (team?.value)
        parsedUrl.searchParams.set('team_id', team.value);
      if (project?.value)
        parsedUrl.searchParams.set('project_id', project.value);
      parsedUrl.searchParams.set(plannable.id.split('activity_')[1], plannable.value);
    } else if (plannable?.value) {
      if (plannable.id.includes("plan")) {
        parsedUrl.searchParams.set('plan_id', plannable.value);
      } else {
        if (team?.value)
          parsedUrl.searchParams.set('team_id', team.value);
        parsedUrl.searchParams.set('issue_id', plannable.value);
      }
    } else {
      if (team?.value)
        parsedUrl.searchParams.set('team_id', team.value);
      if (project?.value)
        parsedUrl.searchParams.set('project_id', project.value);
    }
    await this.getSelectableResources(event, parsedUrl, prev);
    if (plannable.id.includes("plan"))
      this.selectTemplate(event);
  }

  //- 노트 작성 시 프로젝트/팀 변경하면 선택할 수 있는 플랜/이슈 목록 변경
  getSelectablePlannables(event, prev) {
    const team = this.teamTarget;
    const project = this.projectTarget;
    const plannable = this.plannableTarget;
    const url = this.data.get("selectableResourcesUrl");
    let parsedUrl = new URL(`${location.origin}${url}`);
    let activity_id = document.getElementById("activity_id")?.value;
    parsedUrl.searchParams.set('activity_id', activity_id);
    parsedUrl.searchParams.set('team_id', team.value);
    parsedUrl.searchParams.set('project_id', project.value);
    parsedUrl.searchParams.set(plannable.id.split('activity_')[1], plannable.value);
    this.getSelectableResources(event, parsedUrl, prev);
  }

  async getSelectableResources(event, parsedUrl, prev) {
    const current_url = new URL(location);
    const team = this.teamTarget;
    const project = this.projectTarget;
    const plannable = this.plannableTarget;
    current_url.searchParams.forEach((v, name) => {
      if ((!parsedUrl.searchParams.get('team_id') && ['head_quarter_id'].includes(name)) ||
        (!parsedUrl.searchParams.get('project_id') && ['detailed_strategy_id', 'strategy_id'].includes(name)))
        parsedUrl.searchParams.set(name, v);

    });
    let option = plannable.innerHTML;
    plannable.innerHTML = `<option value="loading" data-icon="fa fa-spinner" selected></option>`;
    plannable.disabled = true;
    $('.custom-select').selectpicker('refresh');
    await fetch(parsedUrl, {
        method: 'get',
        headers: {
          "X-CSRF-Token": window.getMetaValue("csrf-token")
        }
      })
      .then(response => response.json())
      .then(body => {
        if (body.message) {
          alert(body.message);
          plannable.innerHTML = option;
          event.target.value = prev;
        } else {
          option = '';
          if (body.project_id) {
            if ([...project.options].some((el) => el.value == body.project_id))
              project.value = body.project_id;
            else
              project.value = "";
          }
          if (body.team_id) {
            if ([...team.options].some((el) => el.value == body.team_id))
              team.value = body.team_id;
            else
              team.value = "";
          }
          body.plannables.forEach((el) => option += `<option value="${el.value}" ${el.selected ? 'selected' : ''}>${el.name}</option>`);
          plannable.innerHTML = option;
        }
        plannable.disabled = false;
        $('.custom-select').selectpicker('refresh');
      });
  }

  //- 노트 작성 시 노트 템플릿 선택 모달창 열기
  showTemplateList(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("url");
    let parseUrl = new URL(`${location.origin}${url}`);
    if (document.getElementById("activity_plan_id")) {
      parseUrl.searchParams.set('plan_id', document.getElementById("activity_plan_id").value)
    }
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.templateTarget,
      "modal"
    );

    fetch(parseUrl)
      .then(response => response.text())
      .then(body => {
        this.templateTarget.innerHTML = body;
        if (this.hasTemplateModalTarget) {
          modalController.open();
        }
      });
  }

  //- 노트 작성 시 노트 템플릿 선택하면 노트 본문에 템플릿 넣기
  async selectTemplate(event) {
    event.preventDefault();
    let url = (event.currentTarget || event.target).getAttribute("url");
    let message = (event.currentTarget || event.target).getAttribute("message");
    let parseUrl = new URL(`${location.origin}${url}`);
    if ((event.currentTarget || event.target).id == 'activity_plan_id') {
      parseUrl.searchParams.set('plan_id', (event.currentTarget || event.target).value)
    }

    await fetch(parseUrl, {
        method: 'put',
        credentials: "same-origin",
        headers: {
          "X-CSRF-Token": window.getMetaValue("csrf-token")
        }
      })
      .then(response => response.json())
      .then(body => {
        if (!body.template) return;
        if (this.contentTarget.value.length > 0)
          if (!confirm(message)) return;
        window.setTemplate(body.template)
        document.getElementById('activity_template_id').value = body.template_id;
      });
  }

  //- 본문이 아닌 별도의 해시태그 필드에서 해시태그 작성
  addHashtag(event) {
    if (event.keyCode == 32 || event.keyCode == 13) {
      event.preventDefault();
      let new_tag = event.currentTarget.value.split('#').pop().replace(/\s/g, "");

      if (new_tag.length > 0) {
        if (this.hashtagFieldTarget.value == "") {
          this.hashtagFieldTarget.value = new_tag;
        } else {
          this.hashtagFieldTarget.value = this.hashtagFieldTarget.value + " " + new_tag;
        }

        event.currentTarget.value = "#";
        var new_tag_badge = document.createElement('span');
        new_tag_badge.innerHTML = "<span class='badge badge-pill badge-info mr-2'>" + new_tag + "<i class='fa fa-times ml-1' data-action='click->activity#removeHashtag'></i></span>";
        this.hashtagBadgeTarget.appendChild(new_tag_badge);
      } else {
        event.currentTarget.value = "#";
      }
    }
  }

  //- 본문이 아닌 별도의 해시태그 필드에서 작성된 해시태그 badge 에 close icon 눌러서 해시태그 제거
  removeHashtag(event) {
    this.hashtagFieldTarget.value = this.hashtagFieldTarget.value.replace(event.currentTarget.parentNode.textContent, "");
    event.currentTarget.parentNode.classList.add('d-none');
  }

  //- 모든 체크박스의 체크를 하거나 제외하거나
  checkAll(event) {
    let url = this.data.get("contentsUrl");
    if (event.currentTarget.checked) {
      this.activityTagsTargets.forEach((el, i) => {
        el.checked = true
      });
    } else {
      this.activityTagsTargets.forEach((el, i) => {
        el.checked = false
      });
    }
    this.loadNoteList(url)
  }

  //- 하나의 체크박스만 선택 / 해제
  checkOne(event) {
    let url = this.data.get("contentsUrl");
    if (event.currentTarget.checked) {
      if (!this.allActivityTagsTarget.checked && (this.activityTagsTargets.filter(checkbox => checkbox.checked).length == this.activityTagsTargets.length)) {
        this.allActivityTagsTarget.checked = true
      }
    } else {
      if (this.allActivityTagsTarget.checked) {
        this.allActivityTagsTarget.checked = false
      }
    }
    if (this.noteListTarget.classList.contains('open-note-list')) {
      this.loadNoteList(url)
    }
  }

  //- 계획 내 노트 모두 인쇄하기
  printNoteList(event) {
    const target = event.currentTarget || event.target;
    target.setAttribute('disabled', true);
    this.printIconTarget.classList.replace("fa-download", "fa-spinner");
    this.printIconTarget.classList.add("fa-spin");
    let url = this.data.get("contentsUrl");

    let checked_one = this.activityTagsTargets.filter(checkbox => checkbox.checked);
    let parseUrl = new URL(`${url}`);
    if (checked_one.length > 0) {
      checked_one.forEach((el, i) => {
        parseUrl.searchParams.append("activity_tags[]", el.value);
      });
    }
    parseUrl.searchParams.append("option", "for_print");
    fetch(parseUrl)
      .then(response => response.text())
      .then(body => {
        if (!this.areaNotToPrintTarget.classList.contains('d-print-none'))
          this.areaNotToPrintTarget.classList.add('d-print-none')
        this.printListTarget.innerHTML = body;
        window.setTimeout(() => {
          window.print();
          this.printIconTarget.classList.remove("fa-spin");
          this.printIconTarget.classList.replace("fa-spinner", "fa-download");
          target.removeAttribute('disabled');
          this.printListTarget.innerHTML = "";
        }, 3000);
        this.areaNotToPrintTarget.classList.remove('d-print-none')
      });
  }

  //- 통제활동 대시보드에서 노트 리스트를 로드하기
  showNoteList() {
    let url = this.data.get("contentsUrl");
    if (this.noteListTarget.classList.contains('open-note-list')) {
      this.noteListTarget.classList.remove('open-note-list');
      this.noteListTarget.classList.add('d-none');
    } else {
      this.noteListTarget.classList.add('open-note-list');
      this.noteListTarget.classList.remove('d-none');
      this.loadNoteList(url)
    }
  }

  loadNoteList(url) {
    this.noteListTarget.innerHTML = "<div class='text-center'><i class='fa fa-spinner fa-pulse fa-4x mt-4 mb-4'></i></div>";

    let checked_one = this.activityTagsTargets.filter(checkbox => checkbox.checked);
    let parseUrl = new URL(`${url}`);
    if (checked_one.length > 0) {
      checked_one.forEach((el, i) => {
        parseUrl.searchParams.append("activity_tags[]", el.value);
      });
    }
    parseUrl.searchParams.append("option", "by_tags");
    fetch(parseUrl)
      .then(response => response.text())
      .then(body => {
        //- 노트 리스트 넣어주고
        this.noteListTarget.innerHTML = body;
        //- 목록에서 즐겨찾기 tooltip 동작할 수 있도록 추가
        $("[data-toggle='tooltip']").tooltip();
      });
  }

}