import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["caller"];

  connect() {
    $(this.callerTargets).popup({
      on: 'click',
      position: 'left center',
    });
  }
}
