import { Controller } from "stimulus"

export default class extends Controller {
    static get targets() {
        return [
            "form",
            "map",
        ];
    }

    connect() {
    }

    //- 맵별 알림 설정 시 map 를 선택해야 form 보여주고, 아니면 숨기기
    toggleMapList() {
        if (this.mapTargets.filter(checkbox => checkbox.checked).length > 0) {
            const selected_maps = this.mapTargets.filter(checkbox => checkbox.checked).map(selected_map => selected_map.value);
            document.querySelector('input[name="map_id"]').setAttribute('value', selected_maps);
            this.formTarget.classList.remove('d-none');
        } else {
            this.formTarget.classList.add('d-none');
        }
    }
}