import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [
      "editableProject",
      "warning",
      "detailedStrategyId",
      "name",
      "description",
      "cancelButton",
    ]
  }

  connect() {
    this.initializeDetailTab();
  }

  //- 프로젝트에 대한 팀의 권한을 변경한 후 일괄 저장할 때 각 프로젝트-팀의 권한을 변경
  editPermission(event) {
    const button = event.target;
    button.setAttribute('disabled', true);
    this.warningTarget.classList.remove('d-none');

    const permissionController = this.application.getControllerForElementAndIdentifier(this.element, "permission");
    permissionController.changeAccess(event);
  }

  initializeDetailTab() {
    if (!this.hasCancelButtonTarget)
      return;
    const cancelButton = this.cancelButtonTarget;
    const formData = new FormData(cancelButton.form);
    const description = formData.get('project[description]');

    cancelButton.addEventListener('click', e => {
      this.descriptionTarget.value = description;
      this.descriptionTarget.setAttribute("readonly", true);
    });
  }

  edit(e) {
    this.descriptionTarget.removeAttribute("readonly");
  }

  //- 프로젝트 편집
  update(event) {
    event.preventDefault();
    const controller = this;
    const currentTarget = event.currentTarget || event.target;
    const url = currentTarget.getAttribute("action");
    const load_url = currentTarget.getAttribute("url");
    const project_id = currentTarget.getAttribute("project-id");

    const formData = new FormData(event.target);
    Rails.ajax({
      type: "PUT",
      url: url,
      headers: {
        "X-CSRF-Token": window.getMetaValue("csrf-token"),
        "Content-Type": "application/json"
      },
      data: formData,
      success: function (data) {
        if (load_url && data.status === "updated") {
          // 맵관리자가 프로젝트 설명 수정
          let load_element = document.getElementById(`project-for-manager-${project_id}`);
          if (!load_element) {
            // 본부장이 프로젝트 설명 수정, KPI 목표 및 실적 수정
            load_element = controller.element;
          }
          controller.fetch_url(load_url, load_element);
        }
      }
    });
  }

  fetch_url(url, target) {
    fetch(url)
      .then(response => response.text())
      .then(body => {
        target.outerHTML = body;
      });
  }

  //- 조건에 부합할 시 프로젝트를 삭제
  destroy() {
    let url = this.data.get("url");
    let ds = this.data.get("ds");
    if (confirm(this.data.get("confirm"))) {
      fetch(url, {
          method: 'delete',
          credentials: "same-origin",
          headers: {
            "X-CSRF-Token": window.getMetaValue("csrf-token")
          }
        })
        .then(response => response.text())
        .then(body => {
          var message = body.split('-')[0];
          var project_count = body.split('-')[1];
          if (message === "destroyed") {
            this.editableProjectTarget.classList.add('d-none')
            alert(this.data.get("alert"))
            if (project_count == "0") {
              document.getElementById("ds-" + ds).classList.remove('d-none')
            }
          } else {
            alert('error!')
          }
        });
    }

  }
}