import Flatpickr from "stimulus-flatpickr";
import "flatpickr/dist/themes/light.css";
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin.js'

export default class extends Flatpickr {
    static get targets() {
        return [ "range", "time", "start" ]
    }

    //- datepicker setting
    initialize() {
        this.config = {
            showMonths: 1,
            animate: false,
            disableMobile: true,
            dateFormat: "Y-m-d"
        };

        //- datepicker range setting
        if (this.hasRangeTarget) {
            this.config['mode'] = 'range';
            this.config['locale'] = {
                rangeSeparator: ' ~ '
            };
        } else if (this.hasStartTarget) {
            this.config['mode'] = 'range'
            if ( document.getElementById('plan_end_date') ) {
                this.config['plugins'] = [ new rangePlugin({ input: "#plan_end_date"}) ]
            } else if ( document.getElementById('issue_end_date') ) {
                this.config['plugins'] = [ new rangePlugin({ input: "#issue_end_date"}) ]
            } else if ( document.getElementById('end_date') ) {
                this.config['plugins'] = [ new rangePlugin({ input: "#end_date"}) ]
            }
        }
    }
}