import { Controller } from "stimulus"

export default class extends Controller {
    static get targets() {
        return [ "templateModal", "templateContent" ]
    }

    //- 일반 사용자가 노트 작성 시 템플릿 고르면서 내용 열어볼 때
    showTemplateContent(event) {
        event.preventDefault();
        let url = event.currentTarget.getAttribute("url");

        if (document.getElementsByClassName("opened-template").length > 0) {
            document.getElementsByClassName("opened-template")[0].classList.add('d-none');
            document.getElementsByClassName("opened-template")[0].classList.remove('opened-template');
        }

        fetch(url)
            .then(response => response.json())
            .then(body => {
                this.templateContentTarget.innerHTML = body.template;
            });
        this.templateContentTarget.classList.remove('d-none');
        this.templateContentTarget.classList.add('opened-template');
    }
}