import { Controller } from "stimulus"

export default class extends Controller {
    static get targets() {
        return [ "unreadBadge" ]
    }

    getMetaValue(name) {
        const element = document.head.querySelector(`meta[name="${name}"]`)
        return element.getAttribute("content")
    }

    //- 노트 및 대화방의 안읽음 수와 badge 제거
    clear_unread_count(event) {
        event.preventDefault();
        let url = event.currentTarget.getAttribute("url");
        Rails.ajax({
            type: "PUT",
            url: url
        });
    }

    //- 알림 로그 읽음 처리
    clear_notification_logs(event) {
        event.preventDefault();
        let url = event.currentTarget.getAttribute("url");
        document.getElementById('notification-logs-paginate').classList.add('d-none')
        fetch(url, {
            method: 'put',
            credentials: "same-origin",
            headers: {
                "X-CSRF-Token": this.getMetaValue("csrf-token")
            }
        })
          .then(response => response.text())
          .then(body => {
              document.getElementById('notification-logs-area').innerHTML = body
          });
    }

}
