import { Controller } from 'stimulus'

export default class extends Controller {
    static get targets() {
        return [ 'picker' ];
    }
    
    connect() {}
    
    set(event) {
        const colorValue = event.currentTarget.getAttribute('data-color-value');
        this.pickerTarget.value = colorValue;
    }
}
