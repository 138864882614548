import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "list", "item" ];

  connect() {}

  validate() {
    const targets = this.itemTargets;
    console.debug(targets);
    const distribution = targets.map(item => ( { [item.data('user-id')]: item.value } ));
    console.debug(distribution);
  }

}