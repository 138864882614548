export default class UnreadCountCacheStore {
  constructor(reload_channel_id) {
    this.reload_channel_id = reload_channel_id
  }

  init() {
    if (!window.unread_count_cache) {
      window.unread_count_cache = {};
    }

    if (!window.unread_count_cache[this.reload_channel_id]) {
      window.unread_count_cache[this.reload_channel_id] = {};
    }
  }

  has_subscription() {
    return !!window.unread_count_cache[this.reload_channel_id].subscription;
  }

  set_value(data) {
    window.unread_count_cache[this.reload_channel_id].data = data;
  }

  get_value() {
    return window.unread_count_cache[this.reload_channel_id].data;
  }

  add_subscription(subscription) {
    window.unread_count_cache[this.reload_channel_id].subscription = subscription;
  }
}
