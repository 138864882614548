import { Controller } from "stimulus"
import consumer from '../channels/consumer'

export default class extends Controller {
    static targets = [
        "breadcrumbHtml",
        "loadedHtml",
        "tabProject", "tabIssue", "tabPlan", "tabDo", "tabCheck", "tabAct"
    ]

    // breadcrumb는 content partial의 한 부분이 아니기 때문에
    // action cable로 변경 내역을 subscribe한다.
    connect() {
        let controller = this;
        let breadcrumbChannel = this.data.get("breadcrumb-channel");
        if (breadcrumbChannel != undefined) {
            this.reload_channel = consumer.subscriptions.create({ channel: 'ReloadChannel', id: breadcrumbChannel }, {
                received(data) {
                    if (controller.hasBreadcrumbHtmlTarget) {
                        controller.breadcrumbHtmlTarget.innerHTML = data.innerHTML
                    }
                }
            })
        }
    }

    disconnect() {
        if (!!this.reload_channel) {
            this.reload_channel.unsubscribe();
        }
    }

    // TODO: brower back에 대해서 대응할 수 있으면 좋다.
    loadContentSection(event) {
        let $hrefLinkEle = $(event.target).closest("a");

        if (this.isEventForPartialLoader($hrefLinkEle)) {
            event.preventDefault();
            this.loadContent($hrefLinkEle, () => {
                let targetTabName = $hrefLinkEle.data("partial-loader-target-tab-name");
                if (this[`hasTab${targetTabName}Target`]) {
                    let targetTab = this[`tab${targetTabName}Target`]
                    $(targetTab).tab('show');
                }
            });
        }
    }

    isEventForPartialLoader($hrefLinkEle) {
        return false
        if (!this.hasLoadedHtmlTarget) {
            return false;
        } else if(!!$hrefLinkEle.data("partial-loader-disabled")) {
            return false;
        } else if (!!$hrefLinkEle.data("method")) {
            return false;
        } else {
            return !!$hrefLinkEle.attr("href");
        }
    }

    loadContent($hrefLinkEle, callback) {
        let targetURL = $hrefLinkEle.attr("href");

        // loading spinner 실행
        this.loadedHtmlTarget.innerHTML = `
            <div class="spinner-border" role = "status" >
                <span class="sr-only">Loading...</span>
            </div >
        `

        fetch(targetURL, { headers: { "partial-loader": 'true' }, redirect: "error"})
            .then(response => response.text())
            .then(body => {
                history.pushState({ turbolinks: {} }, "", targetURL)
                if (this.hasLoadedHtmlTarget) {
                    this.loadedHtmlTarget.innerHTML = body
                    if (!!callback) { callback() }
                }
            })
            .catch(err => {
                // partial-loader는 redirection을 따라가지 않아야 한다.
                // redirection이 걸렸을 경우, 또는 에러가 발생했을 경우,
                // 해당 주소로 이동한다.
                window.location.href = targetURL
            })
    }
}
