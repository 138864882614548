import { Controller } from "stimulus"

export default class extends Controller {
    static get targets() {
        return [ "newForm", "newModal", "awardList", "awards", "userName", "userId" ]
    }

    //- 수여할 구성원 선택하기
    choice(event){
        if(event.currentTarget.checked){
            this.userIdTarget.value = event.currentTarget.id;
            this.userNameTarget.value = event.currentTarget.value;
        }
    }

    //- 새 award 생성할 모달창 열기
    newAward() {
        let url = this.data.get("url");
        fetch(url)
            .then(response => response.text())
            .then(body => {
                this.newFormTarget.innerHTML = body;
                if (this.hasNewModalTarget) {
                    let modalController = this.application.getControllerForElementAndIdentifier(
                        this.newFormTarget,
                        "modal"
                    );
                    modalController.open();
                }
            });
    }

    //- 팀별, 개인별 award 받은 내역 보는 모달창 열기
    showAwards(event) {
        let url = event.currentTarget.getAttribute("url");
        fetch(url)
            .then(response => response.text())
            .then(body => {
                this.awardListTarget.innerHTML = body;
                if (this.hasAwardsTarget) {
                    let modalController = this.application.getControllerForElementAndIdentifier(
                        this.awardListTarget,
                        "modal"
                    );
                    modalController.open();
                }
            });
    }

}