import { Controller } from "stimulus"

export default class extends Controller {
    static get targets() {
        return [ "subMenu" ]
    }

    initialize() {
        // menu item(tab) 클릭시 content 표시
        [...document.querySelectorAll(".tabular.menu .item")].filter(el => el.dataset.tab)
            .map(el => {
                el.addEventListener('click', e => {
                    let target = e.currentTarget || e.target;
                    document.querySelectorAll(".tabular.menu .item").forEach(el => el.classList.remove('active'));
                    target.classList.add('active');
                    document.querySelectorAll(`.ui.tab.segment`).forEach(el => el.classList.remove('active'));
                    document.querySelector(`.ui.tab.segment[data-tab=${target.dataset.tab}]`).classList.add('active');
                })
            })
    }

    //- 사이드 메뉴에서 전략 옆 버튼 클릭 시 해당 전략의 세부전략 펼치기
    openDetailedStrategy(event) {
        let target = event.currentTarget || event.target;
        this.subMenuTarget.innerHTML = "<div class='text-center text-dark-link'><i class='fa fa-spinner fa-pulse fa-2x mt-2 mb-2'></i></div>";
        let url = target.getAttribute("url");
        document.querySelectorAll('.list-inline').forEach((el) => {
            el.classList.remove('d-none');
        });
        document.querySelectorAll('.strategy-body').forEach((el) => {
            el.classList.add('d-none');
        });
        fetch(url)
            .then(response => response.text())
            .then(body => {
                this.subMenuTarget.innerHTML = body;
            });
        [...document.getElementsByClassName("open-content-icon")].forEach((el) => {
            if ((el !== event.target) && el.classList.contains("fa-angle-up")) {
                el.classList.replace("fa-angle-up", "fa-angle-down");
                el.classList.add('animated', 'rotateIn');
            }
        });
        if (target.classList.contains("fa-angle-down")) {
            target.classList.replace("fa-angle-down", "fa-angle-up");
            this.subMenuTarget.classList.remove('d-none');
        }
        else {
            target.classList.replace("fa-angle-up", "fa-angle-down");
            this.subMenuTarget.classList.add('d-none');
        }
        target.addEventListener('animationend', () => {
            target.classList.remove('animated', 'rotateIn')
        });
    }

    //- 사이드 메뉴에서 세부전략 옆 버튼 클릭 시 해당 세부전략의 프로젝트 펼치기
    openProject(event) {
        let target = event.currentTarget || event.target;
        this.subMenuTarget.innerHTML = "<div class='text-center text-dark-link'><i class='fa fa-spinner fa-pulse fa-2x mt-2 mb-2'></i></div>";
        let url = target.getAttribute("url");
        document.querySelectorAll('.ds-inline').forEach((el) => {
            el.classList.remove('d-none');
        });
        document.querySelectorAll('.detailed-strategy').forEach((el) => {
            el.classList.add('d-none');
        });
        fetch(url)
            .then(response => response.text())
            .then(body => {
                this.subMenuTarget.innerHTML = body;
            });
        [...document.getElementsByClassName("open-content-icon")].forEach((el) => {
            if ((el !== event.target) && el.classList.contains("fa-angle-up") && el.classList.contains("project-list")) {
                el.classList.replace("fa-angle-up", "fa-angle-down");
                el.classList.add('animated', 'rotateIn');
            }
        });
        if (target.classList.contains("fa-angle-down")) {
            target.classList.replace("fa-angle-down", "fa-angle-up");
            this.subMenuTarget.classList.remove('d-none');
        }
        else {
            target.classList.replace("fa-angle-up", "fa-angle-down");
            this.subMenuTarget.classList.add('d-none');
        }
        target.addEventListener('animationend', () => {
            target.classList.remove('animated', 'rotateIn')
        });
    }

    //- 사이드 메뉴에서 본부 옆 버튼 클릭 시 해당 본부의 팀 펼치기
    openTeam(event) {
        let target = event.currentTarget || event.target;
        this.subMenuTarget.innerHTML = "<div class='text-center text-dark-link'><i class='fa fa-spinner fa-pulse fa-2x mt-2 mb-2'></i></div>";
        let url = target.getAttribute("url");
        document.querySelectorAll('.list-inline').forEach((el) => {
            el.classList.remove('d-none');
        });
        document.querySelectorAll('.head-quarter-body').forEach((el) => {
            el.classList.add('d-none');
        });
        fetch(url)
            .then(response => response.text())
            .then(body => {
                this.subMenuTarget.innerHTML = body;
            });
        [...document.getElementsByClassName("open-content-icon")].forEach((el) => {
            if ((el !== event.target) && el.classList.contains("fa-angle-up")) {
                el.classList.replace("fa-angle-up", "fa-angle-down");
                el.classList.add('animated', 'rotateIn');
            }
        });
        if (target.classList.contains("fa-angle-down")) {
            target.classList.replace("fa-angle-down", "fa-angle-up");
            this.subMenuTarget.classList.remove('d-none');
        }
        else {
            target.classList.replace("fa-angle-up", "fa-angle-down");
            this.subMenuTarget.classList.add('d-none');
        }
        target.addEventListener('animationend', () => {
            target.classList.remove('animated', 'rotateIn')
        });
    }
}