import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [
      "favoriteButton",
      "favoriteIcon",
      "icon"
    ]
  }

  //- 즐겨찾기에 추가 및 즐겨찾기에서 제외하기
  favorite() {
    let url = this.data.get("url");
    let favored_count = document.getElementById('favored_count');
    fetch(url, {
        method: 'put',
        credentials: "same-origin",
        headers: {
          "X-CSRF-Token": window.getMetaValue("csrf-token")
        }
      })
      .then(response => response.text())
      .then(body => {
        alert(body.split('-')[1]);
        if (body.indexOf("save") !== -1) {
          if (this.hasFavoriteButtonTarget) {
            this.favoriteButtonTarget.classList.replace('btn-outline-info', 'btn-info');
          }
          if (this.hasFavoriteIconTarget) {
            this.favoriteIconTarget.classList.remove('outline');
          }
          if (this.hasIconTarget) {
            this.iconTarget.classList.remove('d-none')
          }
          if (favored_count) ++favored_count.innerHTML;
        } else {
          if (this.hasFavoriteButtonTarget) {
            this.favoriteButtonTarget.classList.replace('btn-info', 'btn-outline-info');
          }
          if (this.hasFavoriteIconTarget) {
            this.favoriteIconTarget.classList.add('outline');
          }
          if (this.hasIconTarget) {
            this.iconTarget.classList.add('d-none')
          }
          if (favored_count) --favored_count.innerHTML;
        }
      });
  }
}